/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
html {
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #9fa1a4;
  background-color: #fafafa !important;
  line-height: 1.5;
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
  height: 100vh;
}

a {
  color: #777;
  transition: 0.3s all ease;
}

a:hover {
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.container {
  z-index: 2;
  position: relative;
}

.center {
  text-align: center !important;
}

.font12 {
  font-size: 12px;
}

.font20 {
  font-size: 20px;
}

.marl-3 {
  margin-left: 5%;
}

.footer-img {
  width: 200px;
  float: right;
  display: block;
}

.font-hover {
  color: #0192d5;
  font-size: 14px;
}

.bg-color {
  background: #0090d2;
  color: white;
}

.mrg3 {
  margin: 3%;
}

.img-news {
  width: 200px;
}

.border-top {
  border-top: 1px solid #f2f2f2 !important;
}

.border-bottom {
  border-bottom: 1px solid #f2f2f2 !important;
}

figure figcaption {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
}

section {
  overflow: hidden;
}

.section {
  padding: 8rem 0;
}

.mt-20 {
  margin-top: 26%;
}

.section-heading {
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(-45deg, #3db3c5, #274685);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.white {
  color: #fff;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: #2d71a1;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 8px;
  left: 10px;
}

.img-med2 {
  max-width: 410px;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }

  .mt-20 {
    margin-top: 5%;
  }
}

/* Default btn sre-tyling */
.btn {
  border: none;
  /* padding: 15px 30px !important;*/
}

.btn.btn-outline-white {
  border: 2px solid #fff;
  background: none;
  color: #fff;
}

.btn.btn-outline-white:hover {
  background: #fff;
  color: #2d71a1;
}

.btn.btn-primary {
  background: #001689;
  color: #fff;
  border-radius: 2rem;
}

.btn-lg-modal-2 {
  padding: 5px 8px;
  font-size: 18px;
  /* line-height: 1.33; */
  border-radius: 6px;
  color: #5584ff;
  /* background-color: #428bca; */
  border: #357ebd solid 2px;
  margin: 1%;
  width: 200px;
}

.btn.btn-primary2 {
  background: #2d71a1;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  width: auto;
  margin: 0 auto;
  position: relative;
  display: block;
}

/* Feature 1 */
.feature-1 .wrap-icon {
  /*margin: 0 auto; */
  height: 55px;
  width: 55px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 1px 1px 0 rgba(42, 42, 42, 0.08),
    0 1px 3px 1px rgba(42, 42, 42, 0.16) !important;
  float: left;
  margin-right: 7%;
}

.feature-1 .wrap-icon.icon-1 {
  background: #fff;
}

.feature-1 .wrap-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #001689;
  font-size: 1.5rem;
  display: block;
}

.feature-1 h3 {
  font-size: 20px;
}

.feature-1 p {
  color: #b1b1b1;
}

/* How work Section */
.cta-section {
  /* background-image: url(./assets/img/ambulance.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
}

.cta-section.transbox::after {
  background-color: #000;
  border: 1px solid black;
  opacity: 0.7;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}

.cta-section2 {
  background: linear-gradient(to right, #274685 0%, #3db3c5 100%);
  color: #fff;
}

.cta-section h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
}

.cta-section2 h3 {
  color: #fff;
  font-size: 2rem;
}

.mg-5 {
  margin: 5%;
}

/* login */

.form-heading {
  color: #fff;
  font-size: 23px;
}
.panel h2 {
  color: #444444;
  font-size: 18px;
  margin: 0 0 8px 0;
}
.panel p {
  color: #777777;
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 24px;
}
.login-form .form-control {
  background: #f7f7f7 none repeat scroll 0 0;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}

.main-div {
  background: #ffffff none repeat scroll 0 0;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  margin: 110px auto 30px;
  max-width: 50%;
  padding: 0px 10px 20px 10px;
  -webkit-box-shadow: 0px 3px 5px 0px rgb(105 105 105 / 44%);
  -moz-box-shadow: 0px 3px 5px 0px rgba(105, 105, 105, 1);
  box-shadow: 0px 3px 5px 0px rgb(105 105 105 / 55%);
  border-radius: 2rem;
}

.main-div2 {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 2rem;
  margin: 110px auto 30px;
  max-width: 85%;
  padding: 0px 10px 20px 10px;
  -webkit-box-shadow: 0px 3px 5px 0px rgb(105 105 105 / 44%);
  -moz-box-shadow: 0px 3px 5px 0px rgba(105, 105, 105, 1);
  box-shadow: 0px 3px 5px 0px rgb(105 105 105 / 55%);
}

.color-mdv {
  color: #0090d2;
}

.loginlogo {
  width: 100%;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.mab-3 {
  margin-bottom: 5%;
}

.loginlogo2 {
  /* width: 100%; */
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  /* -webkit-box-shadow: 0px 3px 5px 0px rgba(105, 105, 105, 1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(105, 105, 105, 1);
  box-shadow: 0px 3px 5px 0px rgba(105, 105, 105, 1); */
  position: relative;
  margin: 0 auto;
  display: flex;
}

.login-form .form-group {
  margin-bottom: 10px;
}
.login-form {
  text-align: center;
}
.forgot a {
  color: #0c87e7;
  font-size: 14px;
  text-decoration: underline;
}
.login-form .btn.btn-primary {
  background: #f0ad4e none repeat scroll 0 0;
  border-color: #f0ad4e;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0;
}
.forgot {
  /* text-align: right; */
  margin-bottom: 20px;
  /* margin-right: 13%; */
}
.botto-text {
  color: #ffffff;
  font-size: 14px;
  margin: auto;
}
.login-form .btn.btn-primary.reset {
  background: #ff9900 none repeat scroll 0 0;
}
.back {
  text-align: left;
  margin-top: 10px;
}
.back a {
  color: #444444;
  font-size: 13px;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .cta-section h2 {
    font-size: 2rem;
  }
  .main-div {
    max-width: 86%;
  }
  .main-div2 {
    max-width: 96%;
  }
  .img-med {
    max-width: 450px;
  }
}

.cta-section2 .btn {
  background: #000000;
  color: #fff;
}

/* Sticky Wrapper */
.sticky-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
}

.sticky-wrapper .site-navbar {
  transition: 0.3s all ease;
}

.sticky-wrapper .site-navbar .site-menu-toggle {
  color: #fff;
}

.sticky-wrapper .site-navbar .site-logo a {
  color: #fff;
}

.sticky-wrapper .site-navbar .site-menu > li > a {
  color: rgba(255, 255, 255, 0.8) !important;
}

.sticky-wrapper .site-navbar .site-menu > li > a:hover,
.sticky-wrapper .site-navbar .site-menu > li > a.active {
  color: #fff !important;
}

.sticky-wrapper.is-sticky .burger:before,
.sticky-wrapper.is-sticky .burger span,
.sticky-wrapper.is-sticky .burger:after {
  background: #000000;
  transition: 0s all ease;
}

.sticky-wrapper.is-sticky .site-navbar {
  background: #fff;
  border-bottom: 1px solid transparent;
  box-shadow: 4px 0 20px -5px rgba(0, 0, 0, 0.2);
}

.sticky-wrapper.is-sticky .site-navbar .site-logo a {
  color: #f2f2f2 !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a {
  color: #fff !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a:hover,
.sticky-wrapper.is-sticky .site-navbar .site-menu > li > a.active {
  color: #2d71a1 !important;
}

.sticky-wrapper.is-sticky .site-navbar .site-menu > li.active a {
  color: #2d71a1 !important;
}

.sticky-wrapper .shrink {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* Contact Form */
.form-control {
  height: 33px;
  border-radius: 13px;
  border: 1px solid #dae0e5;
  /* width: 80%; */
  display: block;
  /* margin: 0 auto; */
}

.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #2d71a1;
}

.php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .error-message br + br {
  margin-top: 25px;
}

.php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Blog */
.post-entry {
  margin-bottom: 60px;
}

.post-entry .post-text .post-meta {
  color: #ccc;
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}

.post-entry .post-text h3 {
  font-size: 20px;
  color: #000000;
}

.post-entry .post-text h3 a {
  color: #000000;
}

.post-entry .post-text h3 a:hover {
  text-decoration: none;
  color: #2d71a1;
}

.post-entry .post-text .readmore {
  color: #2d71a1;
}

.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  float: left;
  background: #fff;
}

.sidebar-box *:last-child {
  margin-bottom: 0;
}

.sidebar-box h3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.categories li,
.sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted gray('300');
  list-style: none;
}

.categories li:last-child,
.sidelink li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.categories li a,
.sidelink li a {
  display: block;
}

.categories li a span,
.sidelink li a span {
  position: absolute;
  right: 0;
  top: 0;
  color: #ccc;
}

.categories li.active a,
.sidelink li.active a {
  color: #000000;
  font-style: italic;
}

.comment-form-wrap {
  clear: both;
}

.comment-list {
  padding: 0;
  margin: 0;
}

.comment-list .children {
  padding: 50px 0 0 40px;
  margin: 0;
  float: left;
  width: 100%;
}

.comment-list li {
  padding: 0;
  margin: 0 0 30px 0;
  float: left;
  width: 100%;
  clear: both;
  list-style: none;
}

.comment-list li .vcard {
  width: 80px;
  float: left;
}

.comment-list li .vcard img {
  width: 50px;
  border-radius: 50%;
}

.comment-list li .comment-body {
  float: right;
  width: calc(100% - 80px);
}

.comment-list li .comment-body h3 {
  font-size: 20px;
}

.comment-list li .comment-body .meta {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  color: #ccc;
}

.comment-list li .comment-body .reply {
  padding: 5px 10px;
  background: #e6e6e6;
  color: #000000;
  text-transform: uppercase;
  font-size: 14px;
}

.comment-list li .comment-body .reply:hover {
  color: #000000;
  background: #e3e3e3;
}

.search-form {
  background: #f7f7f7;
  padding: 10px;
}

.search-form .form-group {
  position: relative;
}

.search-form .form-group input {
  padding-right: 50px;
}

.search-form .icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.la,
.las {
  font-family: 'Line Awesome Free';
  font-weight: 900;
}
.la,
.lab,
.lad,
.lal,
.lar,
.las {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

/* Team Section
--------------------------------*/
#team .team-block {
  -webkit-box-shadow: 2px 6px 20px 0px rgba(194, 194, 194, 1);
  -moz-box-shadow: 2px 6px 20px 0px rgba(194, 194, 194, 1);
  box-shadow: 2px 6px 20px 0px rgba(194, 194, 194, 1);
  height: 254px;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 10px;
}

@media (max-width: 991px) {
  #team .team-block {
    margin-bottom: 30px;
  }
}

#team .team-block img {
  width: 100%;
}

#team .team-block .team-content {
  background: #fff none repeat scroll 0 0;
  bottom: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.19);
  bottom: -147px;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  transition: all 0.5s ease-in-out 0s;
}

#team .team-block .team-content ul {
  display: block;
  width: 100%;
}

#team .team-block .team-content ul li {
  display: inline-block;
}

#team .team-block .team-content ul li a {
  color: #626262;
}

#team .team-block .team-content ul li a .fa {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.3s;
}

#team .team-block .team-content ul li a .fa:hover {
  color: #71c55d;
}

#team .team-block .team-content ul > span {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

#team .team-block:hover .team-content {
  bottom: 0px;
  transition: all 0.5s ease-in-out 0s;
}

/* News Section
--------------------------------*/
#noticias .testimonials-content {
  padding: 29px 20px;
  background-color: #0192d5;
  -webkit-box-shadow: 4px 4px 6px 1px rgba(153, 153, 153, 1);
  -moz-box-shadow: 4px 4px 6px 1px rgba(153, 153, 153, 1);
  box-shadow: 4px 4px 6px 1px rgba(153, 153, 153, 1);
  min-height: 300px;
}

#noticias .top-top > h4 {
  font-family: 'Roboto' sans-serif;
  font-size: 25px;
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

#noticias .top-top > h4 {
  font: 700 12px Lato, sans-serif;
}

#noticias .top-top span {
  font-size: 12px;
  font-weight: 300;
  margin-left: 26px;
}

#noticias .btm-btm ul li {
  float: left;
  margin: 0 8px;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  background: #ddd(161, 75, 5) none repeat scroll 0 0;
  cursor: pointer;
  height: 12px !important;
  width: 12px !important;
  border: 1px solid #0192d5;
  content: '';
}

#noticias .btm-btm .active {
  background: #0192d5;
}

.carousel-indicators {
  position: relative;
  bottom: 0;
  left: 0;
  bottom: 0 !important;
  z-index: 15;
  width: auto;
  padding-left: 0;
  margin: 30px auto 0 auto;
  margin-right: auto;
  text-align: center;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}

.carousel-indicators li {
  margin: 0;
}

/* Pricing Table Section
--------------------------------*/
#pricing .block-pricing {
  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 4px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  position: relative;
  width: 100%;
}

@media (max-width: 991px) {
  #pricing .block-pricing {
    margin-bottom: 30px;
  }
}

#pricing .block-pricing .table {
  margin-bottom: 0;
  padding: 30px 15px;
  max-width: 100%;
  width: 100%;
}

#pricing .block-pricing .table h4 {
  padding-bottom: 30px;
}

#pricing .block-pricing h2 {
  margin-bottom: 30px;
}

#pricing .block-pricing ul {
  list-style: outside none none;
  margin: 10px auto;
  max-width: 240px;
  padding: 0;
}

#pricing .block-pricing ul li {
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  padding: 12px 0;
  text-align: center;
}

#pricing .block-pricing ul li b {
  color: #3c4857;
}

#pricing .block-pricing .table .table_btn a {
  background: #71c55d;
  color: #fff;
  margin: 0;
  display: inline-block;
}

#pricing .block-pricing .table .table_btn a:hover {
  background: #55b03f;
}

#pricing .block-pricing .table .table_btn a .fa {
  font-size: 13px;
  margin-right: 5px;
}

/* Blog Section
--------------------------------*/
@media (max-width: 991px) {
  #blog .block-blog {
    margin-bottom: 30px;
  }
}

#blog .block-blog img {
  width: 100%;
}

#blog .block-blog .content-blog {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 4px 0 rgba(0, 0, 0, 0.19);
  display: inline-block;
  min-height: 125px;
  padding: 20px 10px;
  width: 100%;
}

#blog .block-blog .content-blog h4 {
  line-height: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 16px;
}

#blog .block-blog .content-blog h4 a {
  color: #555;
  text-decoration: none;
  transition: 0.3s;
}

#blog .block-blog .content-blog h4 a:hover {
  color: #71c55d;
}

#blog .block-blog .content-blog > span {
  float: left;
  font-size: 12px;
}

#blog .block-blog .content-blog a.readmore {
  color: #505050;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  transition: 0.3s;
  letter-spacing: 0;
  text-transform: capitalize;
}

#blog .block-blog .content-blog a.readmore:hover {
  color: #71c55d;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero-section-beat-homepage {
  /* background-image: url(../assets/img/hero-bg.jpg); */
  /* background-image: url(../assets/img/hero-bgKCL2.png); */
  /* background-image: url(../assets/img/hero-bgKCL2.svg); */
  background-image: url(../assets/img/foto-doctora.jpg);
  background-repeat: no-repeat;
  position: relative;
}

.hero-section-beat-login {
  background-image: url(../assets/img/hero-bgKCL3.png);
  /* background-image: url(../assets/img/hero-bgKCL2.svg); */
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  top: -110px;
}

.hero-section-beat-registro {
  /* background-image: url(../assets/img/hero-bgKCL2.png); */
  background-image: url(../assets/img/hero-bgKCL2.svg);
  background-repeat: no-repeat;
  background-position: right;
  position: relative;
  min-height: 880px;
  top: -160px;
}

.img-med {
  max-width: 697px;
}

@media screen and (max-width: 768px) {
  .img-med {
    max-width: 350px;
    left: 13%;
    position: relative;
  }
}
@media screen and (max-width: 992px) {
  .hero-section2 .wave {
    bottom: -180px;
  }
}

.hero-section2 .wave svg {
  width: 100%;
}

/* .hero-section,
.hero-section > .container > .row {
  height: 65vh;
  min-height: 880px;
}

.hero-section2,
.hero-section2 > .container > .row {
  height: 100vh;
  min-height: 736px;
} */

.hero-section.inner-page {
  height: 60vh;
  min-height: 0;
}

.hero-section2.inner-page {
  height: 60vh;
  min-height: 0;
}

.hero-section.inner-page .hero-text {
  transform: translateY(-150px);
  margin-top: -120px;
}

@media screen and (max-width: 992px) {
  .hero-section2.inner-page .hero-text {
    margin-top: -80px;
  }
}

.hero-section2 h5 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  /*margin-bottom: 30px;*/
}

.hero-section2 h3 {
  font-size: 1.5rem;
  color: #fff;
  /*margin-bottom: 30px;*/
}

.hero-section h5 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 700;
  /*margin-bottom: 30px;*/
}

@media screen and (max-width: 992px) {
  .hero-section2 h1 {
    font-size: 2.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .hero-section2 .hero-text-image {
    margin-top: 4rem;
  }
}

.hero-section2 p {
  font-size: 18px;
  color: #fff;
}

.hero-section p {
  font-size: 18px;
  color: #fff;
}

.hero-section2 .iphone-wrap {
  position: relative;
}

@media screen and (max-width: 992px) {
  .hero-section2 .iphone-wrap {
    text-align: center;
  }
}

.hero-section2 .iphone-wrap .phone-2,
.hero-section2 .iphone-wrap .phone-1 {
  position: absolute;
  top: -50%;
  overflow: hidden;
  left: 0;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}

@media screen and (max-width: 992px) {
  .hero-section2 .iphone-wrap .phone-2,
  .hero-section2 .iphone-wrap .phone-1 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}

.hero-section2 .iphone-wrap .phone-2,
.hero-section2 .iphone-wrap .phone-1 {
  width: 250px;
}

@media screen and (max-width: 992px) {
  .hero-section2 .iphone-wrap .phone-1 {
    margin-left: -150px;
  }
}

.hero-section2 .iphone-wrap .phone-2 {
  margin-top: 50px;
  margin-left: 100px;
  width: 250px;
}

@media screen and (max-width: 992px) {
  .hero-section2 .iphone-wrap .phone-2 {
    width: 250px;
    position: absolute;
    margin-top: 0px;
    margin-left: 100px;
  }
}

/*--------------------------------------------------------------
# Nav
--------------------------------------------------------------*/
.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  top: 0px;
  width: 100%;
}

.site-navbar .site-logo {
  position: relative;
  font-size: 24px;
}

.site-navbar .site-logo a {
  color: #fff;
}

.site-navbar .site-logo a:hover {
  text-decoration: none;
}

.site-navbar .site-navigation .site-menu {
  margin-bottom: 0;
}

.site-navbar .site-navigation .site-menu a {
  text-decoration: none !important;
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li {
  display: inline-block;
}

.site-navbar .site-navigation .site-menu > li > a {
  padding: 30px 9px;
  color: #fff;
  display: inline-block;
  text-decoration: none !important;
  font-size: 15px;
}

.site-navbar .site-navigation .site-menu > li > a:hover {
  color: #ffdd00;
  font-weight: bold;
}

.nav-link {
  font-size: 14px;
  color: #fff !important;
  font-weight: bold;
}
.nav-link:hover {
  font-size: 14px;
  color: #ffdd00 !important;
  font-weight: bold;
}

.site-navbar .site-navigation .site-menu > li.active > a {
  color: #fff !important;
}

.site-navbar .site-navigation .site-menu .has-children {
  position: relative;
}

.site-navbar .site-navigation .site-menu .has-children > a {
  position: relative;
  padding-right: 20px;
}

.site-navbar .site-navigation .site-menu .has-children > a:before {
  position: absolute;
  content: '\ea99';
  font-size: 16px;
  top: 52%;
  right: 0;
  transform: translateY(-50%);
  font-family: 'icofont';
}

.site-navbar .site-navigation .site-menu .has-children .dropdown {
  visibility: hidden;
  opacity: 0;
  top: 100%;
  position: absolute;
  text-align: left;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 0;
  margin-top: 15px;
  margin-left: 0px;
  background: #fff;
  transition: 0.2s 0s;
}

@media screen and (max-width: 768px) {
  .site-navbar .site-navigation .site-menu > li > a {
    padding: 0 6px;
    color: #fff;
    text-decoration: none !important;
    font-size: 10px;
  }
}

.site-navbar .site-navigation .site-menu .has-children .dropdown.arrow-top {
  position: absolute;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown.arrow-top:before {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown a {
  text-transform: none;
  letter-spacing: normal;
  transition: 0s all;
  color: #000000;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown .active {
  color: #2d71a1 !important;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 210px;
}

.site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
  padding: 9px 20px;
  display: block;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li
  > a:hover {
  background: #f2f4f6;
  color: #000000;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > a:before {
  content: '\ea75';
  right: 20px;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > .dropdown,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children
  > ul {
  left: 100%;
  top: 0;
}

.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:hover
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:active
  > a,
.site-navbar
  .site-navigation
  .site-menu
  .has-children
  .dropdown
  > li.has-children:focus
  > a {
  background: #f2f4f6;
}

.site-navbar .site-navigation .site-menu .has-children:hover > a,
.site-navbar .site-navigation .site-menu .has-children:focus > a,
.site-navbar .site-navigation .site-menu .has-children:active > a {
  color: #2d71a1;
}

.site-navbar .site-navigation .site-menu .has-children:hover,
.site-navbar .site-navigation .site-menu .has-children:focus,
.site-navbar .site-navigation .site-menu .has-children:active {
  cursor: pointer;
}

.site-navbar .site-navigation .site-menu .has-children:hover > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:focus > .dropdown,
.site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
  transition-delay: 0s;
  margin-top: -10px;
  visibility: visible;
  opacity: 1;
}

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  transform: translateX(110%);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.offcanvas-menu .site-mobile-menu {
  transform: translateX(0%);
}

.site-mobile-menu .site-mobile-menu-header {
  width: 100%;
  float: left;
  padding-left: 20px;
  padding-right: 20px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
  float: right;
  margin-top: 8px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
  font-size: 30px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 0px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s all ease;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
  float: left;
  margin-top: 10px;
  margin-left: 0px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
  display: inline-block;
  text-transform: uppercase;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a img {
  max-width: 70px;
}

.site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
  text-decoration: none;
}

.site-mobile-menu .site-mobile-menu-body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 52px);
  padding-bottom: 150px;
}

.site-mobile-menu .site-nav-wrap {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a {
  padding: 10px 20px;
  display: block;
  position: relative;
}

.site-mobile-menu .site-nav-wrap a:hover {
  color: #2d71a1;
}

.site-mobile-menu .site-nav-wrap li {
  position: relative;
  display: block;
}

.site-mobile-menu .site-nav-wrap li.active > a {
  color: #2d71a1;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 20;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse:before {
  font-size: 12px;
  z-index: 20;
  font-family: 'icofont';
  content: '\ea99';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-180deg);
  transition: 0.3s all ease;
}

.site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
  transform: translate(-50%, -50%);
}

.site-mobile-menu .site-nav-wrap > li {
  display: block;
  position: relative;
  float: left;
  width: 100%;
}

.site-mobile-menu .site-nav-wrap > li > a {
  padding-left: 20px;
  font-size: 20px;
}

.site-mobile-menu .site-nav-wrap > li > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-mobile-menu .site-nav-wrap > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > a {
  padding-left: 40px;
  font-size: 16px;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul {
  padding: 0;
  margin: 0;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
  display: block;
}

.site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
  font-size: 16px;
  padding-left: 60px;
}

/* Burger */
.burger {
  width: 28px;
  height: 32px;
  cursor: pointer;
  position: relative;
  z-index: 99;
  float: right;
  margin-right: 7%;
}

.burger:before,
.burger span,
.burger:after {
  width: 100%;
  height: 1.5px;
  display: block;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  opacity: 1;
}

.burger:before,
.burger:after {
  transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: top 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    background-color 1.15s cubic-bezier(0.86, 0, 0.07, 1);
  content: '';
}

.left {
  float: left;
}

.burger:before {
  top: 4px;
}

.burger span {
  top: 15px;
}

.burger:after {
  top: 26px;
}

/* Hover */
.burger:hover:before {
  top: 7px;
}

.burger:hover:after {
  top: 23px;
}

/* Click */
.burger.active span {
  opacity: 0;
}

.burger.active:before,
.burger.active:after {
  top: 40%;
}

.burger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
  /*for IE*/
}

.burger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-5);
  /*for IE*/
}

.burger:focus {
  outline: none;
}

/* custom-dropdown */
nav[role='custom-dropdown'] {
  position: relative;
  width: 100%;

  a,
  label {
    color: #ccc;
    text-decoration: none;
    font: 1.4em sans-serif;
    transition: color 0.3s ease-in-out;
  }

  ul {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    list-style: none;

    > li {
      float: left;
      padding: 0.55em 0.55em;
      width: 19%;
      background-image: $img_bg;
      text-align: center;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out;
      transition-property: box-shadow, color;
      box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35);
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.85);
        color: #fff;
        box-shadow: 0 0.05em 0.25em 0 rgba(0, 0, 0, 0.35),
          inset 0 0 0 2em rgba(0, 0, 0, 0.3);

        > a {
          color: #fff;
        }
      }
    }
  }
}

/* small screens */
@media screen and (max-width: 44em) {
  html,
  body {
    margin: 0;
  }

  nav[role='custom-dropdown'] {
    ul {
      display: none;
      height: 100%;
    }

    label {
      position: relative;
      display: block;
      width: 100%;
      min-height: 2.25em;
      padding: 0.45em;
      font-size: 1.1em;
      margin: 0;
      border: 0.15em solid rgba(60, 60, 60, 0.2);
      background-image: $img_bg;
    }

    label:after {
      position: absolute;
      right: 0.25em;
      top: 0;
      content: '\2261';
      font-size: 1.8em;
    }

    input[type='checkbox']:checked ~ label:after {
      color: #222;
    }

    input[type='checkbox']:checked ~ ul {
      display: block;

      > li {
        width: 100%;
        opacity: 0.8;
        text-align: left;

        &:after {
          position: absolute;
          right: 0.25em;
          content: '\203A';
          font: bold 1.4em sans-serif;
        }
      }
    }
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  padding: 5rem 0 2.5rem 0;
}

.footer h3 {
  font-size: 14px;
  margin-bottom: 30px;
  color: #00000082;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer a {
  color: #00000082;
  font-size: 12px;
}

.footer .copyright {
  color: #00000082;
  font-size: 12px;
  margin-bottom: 0px;
}

.credits {
  font-size: 14px;
  padding: 20px;
}

.social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 2rem;
  background: #001689;
  position: relative;
  text-align: center;
  transition: 0.3s background ease;
  color: #ffffff;
  font-size: 18px;
  margin-left: 4px;
}

.social a span {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.social a:hover {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 2rem;
  background: #fff;
  position: relative;
  text-align: center;
  transition: 0.3s background ease;
  border: 1px solid #001689;
  color: #001689;
}

/* New style 8/02/2020*/
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  font-family: inherit;
  border-radius: 2rem !important;
  font-size: 1rem;
}

.text-primary {
  color: #001689 !important;
}

.text-secondarymdv {
  color: #0c87e7 !important;
}

.text-black {
  color: #000 !important;
}
.text-bold {
  font-weight: 600;
}

.text-yellow {
  color: #ffdd00 !important;
}

.bg-primarymdv {
  background-color: #001689;
  color: white;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.bg-yellow {
  background-color: #ffdd00;
  color: #001689 !important;
}

.bg-secondarymdv {
  background-color: #0c87e7;
  color: white;
}

.padding1 {
  padding: 1%;
}

/* .form-control {
    margin: 0 !important;
} */

.css-yk16xz-control {
  border-radius: 2rem !important;
}

.section-scrollable {
  min-height: 200px;
  max-height: 200px;
  overflow-x: hidden;
}

.modal-content {
  border-radius: 2rem;
}

.bg-blue-light {
  background-color: #eff4fe !important;
}

.state-online {
  color: #24f233;
}

.state-busy {
  color: #cf0e10;
}

.state-offline {
  color: #b7b7b4;
}

#overlay-doctor {
  opacity: 0.3;
}

div.overlay-doc {
  background: rgba(175, 175, 175, 0.6);
  /* background:  #d0cfcb; */
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.border-primary {
  border-color: #0c87e7 !important;
}

.border-right-rounded {
  border-top-right-radius: 2rem !important;
  border-bottom-right-radius: 2rem !important;
}
.border-left-rounded {
  border-top-left-radius: 2rem !important;
  border-bottom-left-radius: 2rem !important;
}

.form-control {
  border-radius: 2rem;
}

.input-group-text {
  background-color: #fff;
}

.hr {
  border: 1px solid;
  margin-top: 15px;
  margin-bottom: 10px;
  border-color: #e6e6e6;
}

.pointer {
  cursor: pointer;
}

.scrollMovil-h {
  overflow-x: scroll;
  overflow-y: hidden;
  height: 100px;
  width: 290px;
  white-space: nowrap;
}
.doctor-clic-movil {
  width: 55px;
  height: 55px;
  border: 3px solid #24f233;
}

.btn-info {
  color: #fff;
  background-color: #0c87e7;
  border-color: #0c87e7;
  /* border-radius: 0.8rem; */
}

.btn-info:hover {
  color: #0c87e7;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-info:focus,
.btn-info.focus {
  color: #0c87e7;
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #0c87e7;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #0c87e7;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

/* correción de calendario sobre sidebar movil*/
.rbc-row-content {
  z-index: auto !important;
}

.hr-blue {
  border: 1px solid;
  margin-top: 0px;
  margin-bottom: 3px;
  background: #0c87e7;
  border-color: #0c87e7;
}

.list-group-item-action {
  color: #0c87e7;
  /* padding: 0.25rem 1.25rem; */
}

.list-group-item-action:hover {
  color: #001689;
  /* background-color: #001689; */
  /* border-radius: 2rem;
  padding: 0.25rem 1.25rem; */
}

.footer-sidebar {
  position: fixed;
  width: 200px;
  bottom: 0;
  background: #fff;
}

.arrow-left-app {
  left: -2px !important;
  position: absolute;
  color: #0c87e7;
  /* background-color:#ffffff;
  border-radius: 50%; */
  padding: 2px;
  /* margin-top: 30px */
}

.arrow-right-app {
  position: absolute;
  color: #0c87e7;
  /* background-color:#ffffff;
  border-radius: 50%; */
  /* border: 1px solid #001689; */
  padding: 2px;
  right: -2px !important;
  /* margin-top: 30px */
}

.carousel-control-next,
.carousel-control-prev {
  width: 0 !important;
  opacity: 0.9 !important;
  z-index: 0 !important;
}

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: grey;
}

.title-header-table {
  padding: 15px !important;
  font-size: 18px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.title-header-table2 {
  padding: 5px !important;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.nav-pills .nav-link {
  border-radius: 0;
  color: #001689 !important;
  background-color: #fff;
  font-size: 22px;
  text-transform: uppercase;
  padding: 22px;
  font-weight: bold;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.nav-pills .nav-link:hover {
  color: #001689 !important;
  transition-duration: 2s;
  transition-timing-function: linear;
  background-color: #ffdd00;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  text-transform: uppercase;
  font-size: 22px;
  padding: 22px;
  background-color: #001689;
  font-weight: bold;
}

.btn.btn-yellow {
  background: #ffdd00;
  color: #001689 !important;
  border-radius: 2rem;
}

.badge-notification {
  font-size: 11px;
  position: relative;
  top: -10px !important;
  right: 5px;
}

.login-menu {
  z-index: 1999;
  position: relative;
}

.shadow-sm {
  box-shadow: 0 1px 1px 0 rgba(42, 42, 42, 0.08),
    0 1px 3px 1px rgba(42, 42, 42, 0.16) !important;
}

/* homepage */
.section-howworks {
  background-color: #001689;
  padding-top: 100px;
  padding-bottom: 100px;
  border-top-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.section-benefits {
  background-color: #fff;
  padding-top: 110px;
  padding-bottom: 120px;
}

.section-features {
  padding-top: 480px;
  padding-bottom: 150px;
}

.card-header-title {
  top: 0;
  padding: 0;
  width: 100%;
  left: 0;
  right: 0;
  background-color: #001689;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
  position: absolute;
}

.header-resume-buy {
  top: 0;
  padding: 8px;
  width: 55%;
  left: 16px;
  right: 0;
  bottom: 90px;
  background-color: #001689;
  border-top-left-radius: 3rem !important;
  border-bottom-right-radius: 40rem !important;
  position: absolute;
}

.header-resume-buy-telemedicina {
  top: 0;
  padding: 8px;
  width: 55%;
  left: 16px;
  right: 0;
  bottom: 290px;
  background-color: #001689;
  border-top-left-radius: 3rem !important;
  border-bottom-right-radius: 40rem !important;
  position: absolute;
}

.bg-secondary-light {
  background: #e8f0fe !important;
}

.image-overlay {
  position: absolute;
  top: 122px;
}

.overlapping-section {
  position: absolute;
  top: -24px;
  right: -30px;
}

.nav-overlapping {
  top: 0px;
  position: relative;
}

.hero-overlapping {
  position: absolute;
  z-index: 1999;
  top: 170px;
  left: 0;
  right: 0;
}

.register-overlapping {
  top: 190px;
  padding-bottom: 190px;
}

.forgot-pass-overlapping {
  top: 195px;
  padding-bottom: 210px;
}

.text-overlapping {
  position: absolute;
  z-index: 1999;
  top: 340px;
  left: 45px;
  right: 0;
}

/* CSS Grid Layout  */
.grid-layout {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.grid-layout-hero {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  grid-gap: 20px;
}

.grid-layout-dashboard {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.grid-layout-auto {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: 20px;
}

.fixed-top-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.text-responsive {
  font-size: 22px;
}

.navbar {
  padding: 0rem 0.5rem;
}

.imga {
  width: 100%;
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.nav-short {
  padding: 32.5px;
  border-bottom-left-radius: 2rem;
  width: 70%;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  z-index: 1;
}

.card-body-sub {
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  padding: 25px;
  box-shadow: 0 -7px 10px -5px rgba(42, 42, 42, 0.16);
}

.card-image {
  padding: 40px;
  background-color: #fafafa;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
}

.eye-pass {
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: #fafafa;
}

.footer-simple {
  position: fixed;
  bottom: 0;
  z-index: -2;
}

.box-text {
  padding-left: 140px;
  padding-top: 130px;
}

.importante {
  bottom: 0;
  top: 250px;
  position: relative;
}

.close,
.close:focus,
.close:hover {
  color: inherit !important;
}

/* views enterprise */
.item-table {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  flex-wrap: nowrap;
}

.base-structure {
  justify-content: space-between;
  display: flex;
  padding: 6px;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.label-active-plan {
  display: block;
  text-align: center;
  border-radius: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: white;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  border-radius: 8rem !important;
  margin: 3px;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.scroll-table-payment {
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.total-table {
  background: #001689;
  border-radius: 11px 0px 31px;
}

.border-top-invoice {
  border-top: 1px solid #ddd;
}

.badge-type-profile {
  background: #ffffff;
  border: 2px solid #001689;
  box-sizing: border-box;
  border-radius: 16px;
  margin-left: 27px;
  align-items: center;
  justify-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 8px;
  font-style: normal;
  display: inline-block;
  font-weight: bold;
  font-size: 13px;
  color: #001689;
}

.title-plan-payment {
  position: absolute;
  width: auto;
  height: 64px;
  left: 15px;
  top: 0;
  padding: 18px 30px 18px 18px;
  background: #001689;
  border-radius: 31px 0px 10px;
}

/* fin views enterprise */

.datos-carnet-patient {
  position: absolute;
  display: block;
  justify-content: flex-start;
  top: 260px;
  left: 44px;
  z-index: 22;
  width: 380px;
}
.datos-carnet-patient-modal {
  position: absolute;
  display: block;
  justify-content: flex-start;
  top: 130px;
  left: 40px;
  z-index: 22;
  width: 300px;
}

.datos-carnet-02-patient {
  position: absolute;
  display: block;
  justify-content: flex-start;
  top: 50px;
  left: 44px;
  z-index: 22;
  width: 700px;
}
.benefits-item {
  font-size: 22px;
}

.datos-carnet-02-patient-modal {
  position: absolute;
  display: block;
  justify-content: flex-start;
  top: 18px;
  left: 20px;
  z-index: 22;
  width: 380px;
}
.carnet-modal-item-dependent {
  font-size: 12px !important;
}
.text-dependent-modal {
  font-size: 12px;
}

.background-benefits {
  background-color: #ffdd00;
  width: 38%;
  padding-left: 10px;
  margin-bottom: 14px;
}
.modal-benefits-list {
  font-size: 12px;
}

.background-dependents {
  background-color: #ffdd00;
  width: 47%;
  padding-left: 10px;
  margin-bottom: 14px;
}

.background-title-plan {
  background-color: #ffdd00;
  width: auto;
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 1px;
  padding-right: 2px;
  margin-bottom: 16px;
}

.table th, .table td {
  vertical-align: middle;
}

.label-enterprise-patient {
  width: 316px;
  height: 26.2px;
  background: #ffdd00;
  border-radius: 33px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  line-height: 16px;
  color: #001689;
}

.label-enterprise-patient-2 {
  background: #001689;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 33px;
}
