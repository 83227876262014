.main {
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 600px) {
  .main {
    height: 100vh;
    display: block;
    flex-flow: row wrap;
  }
  /* .timer {
    font-size: 14px !important;
    display: block !important;
    position: relative !important;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  } */
  .img-call {
    padding-top: 25px !important;
    left: 130px !important;
    width: auto !important;
    height: 150px !important;
  }
  .main__chat_window {
    flex-grow: 1;
    min-height: 200px;
    /*max-height: 200px;*/
    max-height: '90%';
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.main__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
}

.main__right {
  flex: 0.3;
}

.main__videos {
  flex-grow: 1;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 40px; */
}

.main__controls {
  background-color: #013777;
}

.main__right {
  background-color: white;
  /* border-left: 1px solid #3d3d42; */
  color: #3d3d42;
}

.main__controls {
  color: #d2d2d2;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.main__controls__block {
  display: flex;
}

.main__controls__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  min-width: 80px;
  cursor: pointer;
  background: transparent;
  border: solid 1px rgba(0, 123, 255, 0.25);
  border-radius: 20px;
  color: white;
  font-size: 14px;
}

.main__controls__button:hover {
  background-color: rgba(0, 123, 255, 0.25);
  border-radius: 20px;
}

.main__controls__button i {
  font-size: 24px;
}

.main__right {
  display: flex;
  flex-direction: column;
}

.main__header {
  padding-top: 5px;
  color: #f5f5f5;
  text-align: center;
}

.main__chat_window {
  flex-grow: 1;
  min-height: 550px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.messages {
  color: #343434 !important;
  list-style: none;
}

.main__message_container {
  padding: 22px 12px;
  display: flex;
}

.main__message_container input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #343434;
}

.leave_meeting {
  color: #eb534b;
}

.unmute,
.stop {
  color: #cc3b33;
}

#video-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

video {
  height: 400px;
  width: 400px;
  object-fit: cover;
  padding: 8px;
  border: 1px blue solid;
}

.buttons-grid {
  position: relative;
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.buttons-grid span {
  color: #013778;
}

.btn-video {
  background: white;
  opacity: 0.8;
  border-radius: 4px;
  padding: 0 2%;
  margin-right: 1%;
}

.btn-video:hover {
  background: white;
  opacity: unset;
  border: solid #42dadf;
}

.black-i {
  color: black;
}

.edit-vi {
  display: block;
  font-size: 27px;
  color: #17839f;
  width: 100%;
}

.carousel-item .card-hea.mrg-7 {
  margin: 2% 7%;
}

.timer {
  font-size: 55px;
  display: block;
  position: absolute;
  top: 0;
  left: 27px;
}

.img-call {
  position: absolute;
  left: 0;
  top: 0;
  width: 350px;
  height: 350px;
  z-index: 2;
}

.container-video-call {
  position: relative;
  width: 400px;
}
