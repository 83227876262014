.main-section {
  /* border: 1px solid #0192d5; */
  background-color: #fff;
}
.profile-header {
  background-color: #001689;
  height: 100px;
  border-top-right-radius: 2rem !important;
  border-top-left-radius: 2rem !important;
  top: -15px;
}
.user-detail {
  margin: -80px 0px 0px 0px;
}
.user-detail img {
  height: 100px;
  width: 100px;
}
.user-detail h5 {
  margin: 15px 0px 5px 0px;
}
.user-social-detail {
  padding: 15px 0px;
  background-color: #17a2b8;
}
.user-social-detail a i {
  color: #fff;
  font-size: 23px;
  padding: 0px 5px;
}

.user-detail .media img {
  height: 58px;
  width: 61px;
}

.line-w {
  border-top: solid 6px white;
  padding: 1%;
}

/* About Me 
---------------------*/
.about-text h3 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 6px;
}
@media (max-width: 767px) {
  .about-text h3 {
    font-size: 35px;
  }
}
.about-text h6 {
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .about-text h6 {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .card-r {
    width: 250px;
  }
}
.about-text p {
  font-size: 18px;
  max-width: 450px;
}
.about-text p mark {
  font-weight: 600;
  color: #001689;
}

.about-list {
  padding-top: 10px;
}

.about-list label {
  color: #001689;
  font-weight: 600;
  margin: 0;
  position: relative;
}
.about-list label:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: -13px;
  width: 1px;
  height: 12px;
  background: #001689;
  transform: rotate(15deg);
  margin: auto;
  /* opacity: 0.5; */
}
.about-list p {
  margin-left: 20px;
  font-size: 15px;
}

@media (max-width: 991px) {
  .about-avatar {
    margin-top: 30px;
  }
}

.counter {
  /* padding: 22px 20px; */
  background: #ffffff;
  border-radius: 10px;
  /* box-shadow: 0 0 30px rgba(31, 45, 61, 0.125); */
}
.about-section .counter .count-data {
  margin-top: 10px;
  margin-bottom: 10px;
}
.about-section .counter .count {
  font-weight: 700;
  color: #001689;
  margin: 0 0 5px;
}
.about-section .counter p {
  font-weight: 600;
  margin: 0;
}
mark {
  background-image: linear-gradient(
    rgba(252, 83, 86, 0.6),
    rgba(252, 83, 86, 0.6)
  );
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}
.theme-color {
  color: #fc5356;
}
.dark-color {
  color: #001689;
}

.doc-pic {
  width: 172px;
  border: solid 4px white;
  border-radius: 5px;
}

.bg-card {
  position: relative;
  /* padding-top: 30px; */
  /* padding-right: 20px; */
  /* padding-bottom: 38px; */
  padding: 20px;
  transition: all 0.35s;
  border-radius: 2rem;
  box-shadow: 2px -3px 5px 0px rgb(243 243 243 / 75%);
  top: -71px;
  background-color: white;
  border: solid 2px #03a9f4;
  /* background: #f9f9f9; */
}

.blue-card {
  /* box-shadow: 10px 10px 5px 0px #dee2e6; */
  /* background-color: #eeeeed; */
  /* color: white; */
  padding: 3%;
  top: -16px;
  position: relative;
  border-radius: 2rem;
}

.check-green {
  font-size: 16px;
  border: solid 3px #24f233;
  border-radius: 30px;
  color: #17d601;
  padding: 0 2%;
}

.check-yellow {
  font-size: 16px;
  border: solid 3px #b7b7b4;
  border-radius: 30px;
  color: #b7b7b4;
  padding: 0 2%;
}

.check-red {
  font-size: 16px;
  border: solid 3px #cf0e10;
  border-radius: 30px;
  color: #cf0e10;
  padding: 0 2%;
}

.user-dashboard .bg-card h1 {
  color: #333333;
  background: #ffffff;
  text-shadow: 2px 2px 0px #ffffff, 5px 4px 0px rgba(0, 0, 0, 0.15);
  padding-left: 4%;
}

.about-avatar {
  color: #333;
}
.profile-image {
  width: 100% !important;
  height: 100% !important;
}

.image-container {
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
  border: 5px solid #fff;
  /* box-shadow: 3px 3px 15px -6px rgba(0, 0, 0, 0.75); */
}

.image-containerMovil {
  height: 100px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
  border: 5px solid #f9f9f9;
  /* box-shadow: 3px 3px 15px -6px rgba(0, 0, 0, 0.75); */
}

.icon-edit-image-profile {
  color: #9ea1a4;
  font-size: 30px;
  cursor: pointer;
}
.edit-icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.bank-accounts-container {
  background-color: #fff;
  border: 1px solid #8997b1;
  border-radius: 2rem;
  padding: 20px;
}
.title-bank-account {
  color: #8997b1;
  font-size: 21px;
  font-weight: 600;
  margin: 0 !important;
}
.card-container-bank-account {
  display: flex;
  width: 100%;
  padding: 5px !important;
}
.card-element {
  float: none !important;
  margin: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  /* width: 100% !important; */
}

.img-size-bank-account {
  width: 80px !important;
}

.image-name-container-card {
  margin: 5px !important;
}

.btn-t {
  outline: none !important;
}

.container-title-accounts {
  display: flex;
  align-items: center;
}
.icon-add-accounts {
  padding: 5px;
  cursor: pointer;
  font-size: 40px;
}
.buttons-container {
  width: 80% !important;
}
.close-button {
  background-color: red;
  border-radius: 2rem;
}
.add-button {
  width: 120px !important;
  margin: 0 !important;
  margin: 10px !important;
}
.avatar-button-container {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  transition: border 0.5s ease-in-out;
}
.avatar-button-container:focus {
  outline: none;
}

.avatar-doctor-button-container:focus {
  outline: none;
}

.avatar-doctor-button-container {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  margin: 0 auto;
  transition: border 0.5s ease-in-out;
  display: flex;
  position: relative;
}

.image-profile-doctor-container {
  height: 150px;
  width: 110px;
  background-repeat: no-repeat;
  background-position: 50%;
  /* border: 3px solid #8997b1; */
  background-size: cover;
  box-shadow: 3px 3px 15px -8px rgba(0, 0, 0, 0.75);
}
.cv-button {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.cv-normal-button {
  cursor: pointer;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  color: #2d71a1;
}
.cv-normal-button:focus {
  outline: none;
}
.cv-normal-button:hover {
  text-decoration: underline;
}

.container-cv {
  display: flex;
  flex-direction: column;
}

.mb-6 {
  margin-bottom: 6%;
}

.border-g {
  border-left: solid #eeeeed 3px;
  border-bottom: solid #eeeeed 5px;
  border-radius: 2rem;
}

.bbbb {
  background-color: #001689;
  height: 154px;
  border-radius: 1rem 1rem 0 0;
}

.img-circle-2 {
  border-radius: 100%;
  width: 120px;
  height: 120px;
}

.cont-r {
  display: block;
  position: relative;
  margin-top: 36%;
  padding: 3%;
  /* border: 1px solid #2d71a1; */
}

.cont-r2 {
  display: block;
  position: relative;
  margin-top: 15%;
  padding: 3%;
  /* border: 1px solid #2d71a1; */
}

.card-r {
  display: inline-block;
  float: left;
  width: 370px;
  padding-left: 0;
}

.card-r2 {
  display: inline-block;
  float: left;
  width: 100%;
  padding-left: 0;
}

.font-blue-r {
  color: #001689;
  font-weight: bold;
}

.font-blue-l {
  color: #0c87e7;
  font-size: 14px;
  font-weight: 300;
}

.db-social .head-profile {
  margin-top: -120px;
  border-radius: 4px;
  position: relative;
}

.widget {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
}
.has-shadow {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
.db-social .head-profile:before {
  content: '';
  background: rgba(255, 255, 255, 0.6);
  height: 20px;
  width: 90%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
}
.db-social .head-profile:after {
  content: '';
  background: rgba(255, 255, 255, 0.3);
  height: 20px;
  width: 80%;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
}
.db-social .widget-body {
  padding: 1rem 1.4rem;
}
.widget-body {
  padding: 1.4rem;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.db-social .image-default img {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 1);
  z-index: 10;
}
.db-social .infos {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}
.db-social h2 {
  color: #2c304d;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.db-social .location {
  color: #aea9c3;
  font-size: 1rem;
}
.db-social .follow .btn {
  padding: 10px 30px;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-shadow,
.btn-shadow a {
  color: #5d5386;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.db-social .head-profile .actions {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.actions {
  z-index: 999;
  display: block;
}
.actions.dark .dropdown-toggle {
  color: #2c304d;
}
.actions .dropdown-toggle {
  color: #98a8b4;
  background: none;
  border: none;
  padding: 0;
  font-size: 1.7rem;
}
.actions .dropdown-menu {
  border: none;
  min-width: auto;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1.4rem 1.8rem;
  text-align: left;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}

.actions .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}
.actions .dropdown-menu a {
  color: #2c304d;
  font-weight: 500;
}

.db-social .head-profile li:first-child {
  padding-left: 0;
}
.db-social .head-profile li {
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
}
.db-social .head-profile li .counter {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 600;
}
.db-social .head-profile li .heading {
  color: #aea9c3;
  font-size: 1rem;
}

.profile-navbar .nav-item .nav-link {
  color: #6c7293;
}

.profile-navbar .nav-item .nav-link.active {
  color: #464dee;
}

.profile-navbar .nav-item .nav-link i {
  font-size: 1.25rem;
}

.profile-feed-item {
  padding: 1.5rem 0;
  border-bottom: 1px solid #e9e9e9;
}
.img-sm {
  width: 43px;
  height: 43px;
}
.container-dependent {
  max-height: 350px;
  overflow-y: auto;
}