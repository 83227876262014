.clinic_map_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 20px;
}
.clinic_map_section ul li {
  color: #041689;
  font-size: 20px;
}
.clinic_map_section .header {
  font-size: 30px;
  font-weight: 700;
  color: #041689;
  margin-top: 20px;
}
.clinic_map_section .main_text_container {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.clinic_map_section .main_container {
  display: flex;
  flex-direction: row;
}

.clinic_map_container {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 10px;
}

@media (max-width: 500px) {
  .clinic_map_section ul li {
    font-size: 14px;
  }
  .clinic_map_section .header {
    font-size: 20px;
  }
  .clinic_map_section .clinic_map {
    width: 80%;
    text-align: center;
    padding: 10px;
  }
  .clinic_map_section .clinic_map_container {
    display: flex;
  }

  .clinic_map_section .main_text_container {
    padding: 20px;
  }
}

@media (max-width: 1000px) {
  .clinic_map_section .main_container {
    flex-direction: column;
  }
}
