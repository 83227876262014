.button-medicines {
  background-color: transparent;
  border: none;
  outline: none !important;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.acordeon-card {
  border: 'none';
  width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.card-styles {
  border: 'none';
  width: 100%;
  border-radius: 1rem;
  margin: 0;
  padding: 0;
}
.historial-container {
  background-color: #fff;
  border-radius: 1.5rem !important;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}

.icon-position {
  margin-left: auto !important;
}

.acordion-toogle {
  display: flex;
  align-items: center;
  background-color: #eff4fe !important;
  justify-content: flex-start;
}
.update-button-medicine {
  width: 40% !important;
  margin: 0 !important;
}
.cancel-update-buttons {
  color: red;
  font-weight: 600;
  font-size: 1.4rem;
}
.switch-properties {
  background-color: red !important;
}
.btn-modals {
  background-color: #078ecf;
  color: #fff;
}
.btn-modal-cancel {
  background-color: #ff4747;
  color: #fff;
}
.btn-modal-cancel:hover {
  color: #fff;
}
.btn-modals:hover {
  color: #fff;
}
.shopping :hover {
  background-color: #f9fafe;
}
.shopping {
  cursor: pointer;
}
.icon-delete-item-shopping {
  color: #ff4747;
  size: 20px;
}

.container-consulting-check {
  cursor: pointer;
  border-radius: 1rem !important;
  box-shadow: none;
  border: 1px solid #ccc;
}
.container-consulting-check:hover {
  border: 2px solid #078ecf;
  box-shadow: none;
}
.form-card-modal {
  padding-left: 5rem;
  padding-right: 5rem;
}
.input-card-modal-form {
  margin-top: 0.7rem !important;
  margin-bottom: 0.7rem !important;
}
.span-form-modal {
  padding: 0 !important;
  margin: 0 !important;
}

.checkbox-card-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.buttons-others {
  border: none;
  cursor: pointer;
  background: transparent;
  color: #e91e62;
  outline: none !important;
}
