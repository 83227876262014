.header-top i {
  font-size: 18px;
}

.bg-dash {
  background-color: #f6f7fa;
}

.text-dash {
  color: #6c757d;
}

.btn-wallet {
  border: solid 2px;
  border-radius: 11px;
  padding: 3%;
  width: 220px;
  margin: 0 auto;
  font-size: 15px;
}

.mt-20 {
  margin-top: 29%;
}

/* .menu-div {
  width: 200px;
  height: 100vh;
  padding-left: 2%;
} */

.border-select-card {
  border: 5px solid #28a745;
}

.btn-wal {
  border: solid 1px;
  border-radius: 11px;
  padding: 3%;
  width: 220px;
  margin: 0 auto;
  background-color: #0090d2;
  font-size: 15px;
}

.btn-credit {
  border: solid 1px #007bff;
  background: white;
  color: #007bff;
  border-radius: 5px;
  float: right;
}

.credit-card-item .credit-card-number {
  letter-spacing: 2px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.credit-card-item .credit-card-holder,
.credit-card-item .credit-card-expiration {
  letter-spacing: 3px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}

.credit-card-item .credit-card-holder {
  font-size: 1rem;
  left: 2rem;
  width: calc(100% - 9rem);
  bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.credit-card-item .credit-card-type.visa {
  background-size: 100%;
  width: 20%;
  height: 15%;
  bottom: 1rem;
  right: 1.5rem;
}

.credit-card-item .credit-card-holder,
.credit-card-item .credit-card-expiration {
  letter-spacing: 3px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}

.credit-card-item .credit-card-informations {
  left: 2rem;
  width: calc(100% - 4rem);
  bottom: 50%;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  -ms-transform: translateY(50%);
  -o-transform: translateY(50%);
  transform: translateY(50%);
  text-align: left;
}
.credit-card-item-wrapper {
  width: 40%;
  padding: 0 1rem;
  margin-top: 2rem;
}

.credit-card-item .credit-card-holder {
  font-size: 1rem;
  left: 2rem;
  width: calc(100% - 9rem);
  bottom: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.credit-card-item .credit-card-holder,
.credit-card-item .credit-card-expiration {
  letter-spacing: 3px;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  color: white;
}
.credit-card-item .credit-card-informations,
.credit-card-item .credit-card-holder,
.credit-card-item .credit-card-default,
.credit-card-item .credit-card-type {
  position: absolute;
}

.credit-card-list-items {
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 65rem;
  /* margin: 0 auto; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

.credit-card-item {
  width: 85%;
  padding-bottom: 50%;
  border-radius: 1rem;
  color: white;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  background: #2257ca;
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, #2257ca),
    color-stop(100%, #468def)
  );
  background: linear-gradient(45deg, #2257ca 0%, #468def 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2257ca', endColorstr='#468def', GradientType=1);
}

.input-group-prepend-2 {
  margin-right: -1px;
  left: 3%;
  position: relative;
  display: inline-block;
}

.form-control-2 {
  position: relative;
  width: 80%;
  height: 40px;
  flex: none;
  border: solid 1px #dee2e6;
}

.CustomCard {
  padding-top: 20px;
  margin: 10px 0 20px 0;
  background-color: rgba(214, 224, 226, 0.2);
  border-top-width: 0;
  border-bottom-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 15px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.CustomCard.hoverCustomCard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
}

.CustomCard.hoverCustomCard .CustomCardheader {
  background-size: cover;
  height: 85px;
}

.CustomCard.hoverCustomCard .avatar {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.CustomCard.hoverCustomCard .avatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.CustomCard.hoverCustomCard .info {
  padding: 4px 8px 10px;
}

.CustomCard.hoverCustomCard .info .desc {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.CustomCard.hoverCustomCard .bottom {
  padding: 20px 5px;
  margin-bottom: -6px;
  text-align: center;
}

/*CHAT*/
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 100%;
  border-right: 1px solid #c4c4c4;
  border-radius: 2rem;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.dropdown .fit-w {
  width: fit-content;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 17px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg {
  width: 100%;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file,
.input-group-perfil-3 {
  /* position: relative; */
  /* width: 50%;
  height: 40px; */
  /* width: 85%; */
  height: 38px;
  /* flex: none; */
}

/* .input-group-prepend {
  margin-right: -1px;
  left: 20%;
  left: 2%;
  position: relative;
  display: inline-block;
} */

.sales h3 {
  color: #8492af;
  /* float: left; */
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  word-break: break-word;
  /* padding: 9px 0 0; */
}

.img-chat {
  width: 34px;
  border-radius: 50%;
}
.sent_msg p {
  background: #013777 none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 17px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}
.sent_msg {
  float: right;
  width: 46%;
}

.padding5 {
  padding: 5%;
}

.sales h3.padding3 {
  padding: 3%;
}

.padding3 {
  padding: 3%;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.doublecheck {
  color: #71c55d;
}

.btn-chat {
  border: solid 1px #013777;
  background: white;
  color: #013777;
  border-radius: 5px;
  float: right;
  font-size: 14px;
}

.chatblue {
  background: #001689;
  color: white;
}
.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #001689 none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 2px;
  width: 45px;
}
.messaging {
  padding: 0 0 50px 0;
}
.msg_history {
  height: 516px;
  overflow-y: auto;
}

.wid100 {
  width: 100%;
}

.btn-udoc {
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  padding: 1rem;
  font-size: 0.81rem;
  border-radius: 18px;
  width: 100%;
  font-size: 1.2rem;
  background: white;
  color: #001689;
}

.btn-udoc .bluei3 {
  display: inline-block;
  font-size: 1.5rem;
  /* height: 2em; */
  overflow: visible;
  /* vertical-align: -.125em; */
  color: rgb(0 123 255 / 62%);
  width: 1.875em;
}

.h-box {
  max-height: 400px;
  overflow: auto;
}
/*END CHAT*/

/*INVOICE*/

.receipt-main {
  background: #ffffff none repeat scroll 0 0;
  /* border-bottom: 7px solid #dae0e5; */
  /* border-top: 12px solid #9f181c; */
  margin-top: 29px;
  /* margin-bottom: 50px; */
  /* padding: 40px 30px !important; */
  position: relative;
  /* box-shadow: 0 1px 21px #acacac; */
  color: #333333;
  /* font-family: open sans; */
  width: 100%;
}

.pad3 {
  padding-left: 7px;
}

.receipt-main p {
  color: #333333;
  font-family: open sans;
  line-height: 1.42857;
}
.receipt-footer h1 {
  font-size: 15px;
  font-weight: 400 !important;
  margin: 0 !important;
}
.receipt-main::after {
  background: #dae0e5 none repeat scroll 0 0;
  content: '';
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: -13px;
}
.receipt-main thead {
  background: #fff none repeat scroll 0 0;
}
.receipt-main thead th {
  color: #001689;
  /* font-size: 20px; */
}
.receipt-right h5 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 7px 0;
}
.receipt-right p {
  font-size: 12px;
  margin: 0px;
}
.receipt-right p i {
  text-align: center;
  width: 18px;
}
.receipt-main td {
  padding: 9px 20px !important;
}
.receipt-main th {
  padding: 13px 20px !important;
}
.receipt-main td {
  font-size: 15px;
  font-weight: initial !important;
  border: none;
}
.receipt-main td p:last-child {
  margin: 0;
  padding: 0;
}
.receipt-main td h2 {
  font-size: 20px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}
.receipt-header-mid .receipt-left h1 {
  color: #0e1a35;
  font-size: 30px;
  font-weight: 500;
  margin: 0 20px;
  /* padding: 21px 0; */
}

.border-primary {
  border-color: #007bff !important;
}

.card-hea {
  display: inline-block;
  /* float: left; */
  /* width: 370px; */
  /* margin-left: 0.75rem; */
  /* padding-right: 0.75rem; */
  padding-left: 0;
  /* border-right: 1px solid lighten($darkblue, 70%); */
  border-color: #007bff !important;
  /* border: solid 2px; */
}

.rateus {
  width: 500px;
  /* border: solid 1px; */
  padding: 1%;
}

.i-success {
  color: #0192d5;
  font-size: 7em;
  margin: 30px;
  font-weight: lighter;
}

.transp {
  background-color: transparent;
  border: none;
}

.btn.btn-primary3 {
  background: #001689;
  color: #fff;
  width: 200px;
  margin: 0 auto;
  position: relative;
  display: block;
  border-radius: 2rem;
}

.receipt-header-mid .receipt-left span {
  color: #0e1a35;
  font-weight: 500;
  margin: 0 20px;
  /* padding: 21px 0; */
}
.receipt-header-mid {
  /* margin: 24px 0; */
  overflow: hidden;
}

#container {
  background-color: #dcdcdc;
}

/*INVOICE*/
.user-row {
  margin-bottom: 14px;
}

.user-row:last-child {
  margin-bottom: 0;
}

.align-input {
  display: block;
  position: relative;
  width: 94%;
  float: right;
}

.align-i {
  display: block;
  width: 2%;
  position: relative;
  float: left;
  font-size: 20px;
  padding: 1%;
}

.dropdown-user {
  margin: 13px 0;
  padding: 5px;
  height: 100%;
}

.dropdown-user:hover {
  cursor: pointer;
}

.demoPadder {
  padding-top: 15px;
}

.btn-default {
  color: #007aff;
  background-color: #fff;
  border-color: #007aff;
}

.table-user-information > tbody > tr {
  border-top: 1px solid rgb(221, 221, 221);
}

.table-user-information > tbody > tr:first-child {
  border-top: 0;
}

.table-user-information > tbody > tr > td {
  border-top: 0;
}
.toppad {
  margin-top: 20px;
}

.font2em {
  font-size: 2em;
}

.bg-detail {
  /* background: #e0e0e0; */
  padding: 20px;
}

.panel-info {
  border: solid 2px #bce8f1;
  padding: 2%;
  border-radius: 11px;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
table.greyGridTable {
  border: 2px solid #ffffff;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
table.greyGridTable td,
table.greyGridTable th {
  border: 2px solid #ffffff;
  padding: 3px 4px;
}
table.greyGridTable tbody td {
  font-size: 14px;
}
table.greyGridTable td:nth-child(even) {
  background: #ebebeb;
}
table.greyGridTable thead {
  background: #ffffff;
  border-bottom: 3px solid #0090d2;
}
table.greyGridTable thead th {
  font-size: 16px;
  font-weight: normal;
  color: #504747;
  text-align: center;
  border-left: 2px solid #0090d2;
  margin-top: 4%;
}

table.greyGridTable thead th:first-child {
  border-left: none;
}

table.greyGridTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  border-top: 4px solid #333333;
}
table.greyGridTable tfoot td {
  font-size: 14px;
}

.login-header {
  display: inline-block;
  width: 100%;
  background: #0e1a35;
}

.scroll-doctor {
  min-height: 450px;
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-consulting-room {
  min-height: 570px;
  max-height: 570px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-h {
  overflow: auto;
  white-space: nowrap;
}

.scroll-date {
  min-height: 200px;
  overflow: overlay;
  margin-bottom: 20px;
}

.mar-2 {
  margin: 2%;
}

.padbutton {
  padding: 10px 54px;
}

.mar-5 {
  margin: 5%;
}
.pad20 {
  padding: 7px;
  margin-right: 2%;
}

.btn-call {
  border: solid 1px #b6b9bc;
  background: white;
  color: black;
  border-radius: 5px;
}

.label-default {
  background-color: #999;
}
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.content .form-content {
  padding: 10%;
}
.content .form-content .input-group .input-group-text {
  background: #333;
  color: #fff;
  border: none;
  border-radius: 0;
  font-weight: 600;
}
.content .form-content .input-group input {
  border-radius: 0;
}
.content .form-content .input-group input:focus {
  border-color: transparent;
}
.content .form-content h4 {
  margin-bottom: 5%;
}
.content .form-content button {
  background: #333;
  color: #fff;
  border-radius: 0;
  width: 20%;
  font-weight: 600;
}
.c-star {
  color: #d9ab00;
}

.tag-star {
  background: #f6f6f8;
  border-radius: 20%;
  padding: 0 1%;
}

.dp {
  border: 10px solid green;
  transition: all 0.2s ease-in-out;
}

.login-signup {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.img-doc {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid;
  margin-right: 30px;
  margin-bottom: 10px;
}

.img-doc-p {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 5px solid;
  margin-bottom: 10px;
}

.font86 {
  font-size: 65px;
}

.bg-tele {
  display: block;
  position: relative;
  background: #0192d5;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  right: -45px;
  overflow: hidden;
  z-index: 0;
  height: 200px;
  border: solid 1px;
}

.bg-home-tele {
  text-align: center;
  display: block;
  position: relative;
  background: #fff;
  padding: 13px;
  /* border-top-left-radius: 2rem; */
  /* border-bottom-left-radius: 2rem; */
  /* right: calc(100% - 104%); */
  /* overflow: hidden; */
  /* z-index: 0; */
  /* height: 200px; */
  border: solid 2px #f5f5f5;
  /* background: linear-gradient( to bottom, #e0f3fa 0%, #d8f0fc 50%, #b8e2f6 51%, #b6dffd 100% ); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgb(193 193 193 / 0%);  */
  border-radius: 0.8rem;
  margin-bottom: 3%;
}

.bg-home-tele:hover {
  text-align: center;
  display: block;
  transition-duration: 2s;
  transition-timing-function: linear;
  position: relative;
  background: #ffdd00;
  padding: 13px;
  border: none;
  border-radius: 1rem;
  margin-bottom: 3%;
}

.blue-home {
  background-color: rgb(212, 212, 212);
  border-radius: 2rem !important;
  color: white;
}

.sales .user-detail .img-bg-doc {
  width: 252px;
  z-index: 2;
  position: absolute;
  bottom: -46px;
  height: auto;
  left: 20px;
}

.icon-h {
  width: 35px;
  display: block;
  position: relative;
  margin: 0 auto;
}

.icon-hm {
  height: 40px;
}

.menu-f {
  /* padding-left: 13px; */
  font-size: 17px;
  /* color: #17a2b8; */
  font-weight: lighter;
}

.font-blue {
  color: #001689;
  font-weight: bold;
}

.font-red {
  color: red;
  font-weight: bold;
}
.font-green {
  color: green;
  font-weight: bold;
}
.text-sw {
  color: #ffffff;
  text-shadow: 3px 5px 2px #474747;
}

.btn.btn-primary-b {
  background: #2d71a1;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  width: auto;
  margin: 6% 0;
  /* position: relative; */
  /* display: block; */
  font-size: 18px;
  padding: 6%;
}

.form-control-search {
  height: 42px;
  border-radius: 13px;
  border: 1px solid #0192d5;
  width: 80%;
  display: block;
  /* margin: 0 auto; */
}

.top26 {
  top: 26px;
}

.fontchat {
  font-size: 20px;
  color: #007bff;
}
.i-plus-d {
  font-size: 19px;
  /* margin-right: 15px; */
  color: #007bff;
}

.btn-lg-modal {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
  margin: 1%;
}

.i-close {
  display: block;
  position: relative;
  /* border: solid rebeccapurple; */
  float: right;
  padding: 1%;
}

.i-search {
  display: flex;
  position: relative;
  float: right;
  font-size: 25px;
  top: -36px;
  color: #0192d5;
  left: -37px;
}

.btn.btn-telem {
  background: white;
  color: #0192d5;
  width: 247px;
  display: block;
  position: relative;
  float: right;
  margin: 20px;
  font-size: 20px;
}

.btn.btn-telem:hover {
  color: white;
  background: #0192d5;
  border: solid 2px gray;
}

.img-doc-2 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-left: 22px;
}

.margr {
  margin-right: 3%;
}

.margr-2 {
  margin: 0 4%;
}

.date-label {
  font-size: 20px;
  /* border-bottom: solid 2px #007bff; */
  color: blue;
  padding: 2px;
  margin: 0 4%;
}

.i-plus {
  font-size: 30px;
  margin-right: 20px;
  color: #007bff;
}

.i-plus-c {
  font-size: 25px;
  margin-right: 15px;
  color: #007bff;
}

.i-plus-l {
  font-size: 20px;
  margin-right: 20px;
  color: #007bff;
}

.star-y {
  color: #deb217;
}

.f-right {
  float: right;
}

.pad-left {
  padding-left: 7%;
}

.center-div {
  margin: 0 auto;
  display: block;
}

.eye-b {
  background-color: #0192d5;
  color: white;
  font-size: 19px;
  font-weight: bold;
}

.div-cnt-med {
  display: block;
  position: relative;
  margin: auto;
}
.center-div li {
  list-style: none;
  padding-bottom: 3px;
}

.paddb3 {
  padding-bottom: 3%;
}

/*Profile Card 1*/
.profile-card-1 {
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  float: left;
  overflow: hidden;
  width: 100%;
  color: #ffffff;
  text-align: center;
  height: auto;
  border: none;
}
.profile-card-1 .background {
  /* width: 100%; */
  vertical-align: top;
  opacity: 0.9;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transform: scale(4);
}
.profile-card-1 .card-content {
  /* width: 100%; */
  /* padding: 15px 25px; */
  position: relative;
  /* left: 0;
  top: 50%;
  margin-top: 2%; */
}
.profile-card-1 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  max-width: 150px;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}
.profile-card-1 h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 25px;
}
.profile-card-1 h2 small {
  display: block;
  font-size: 15px;
  margin-top: 10px;
}

.call-animation {
  background: #fff;
  width: 150px;
  height: auto;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  border: solid 5px #fff;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes play {
  15% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4),
      0 0 0 20px rgba(255, 255, 255, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4),
      0 0 0 30px rgba(255, 255, 255, 0.2);
  }
}

.profile-card-1 i {
  display: inline-block;
  font-size: 29px;
  color: #ffffff;
  text-align: center;
  background: #0c87e7;
  border: 2px solid #fff;
  width: 65px;
  height: 62px;
  /* line-height: 27px; */
  border-radius: 50%;
  margin: 0 15px;
  padding: 8px 0;
}
.profile-card-1 .icon-block {
  float: left;
  width: 100%;
  margin-top: 15px;
}
.profile-card-1 .icon-block a {
  text-decoration: none;
}
.profile-card-1 i:hover {
  background-color: #fff;
  color: #2e3434;
  text-decoration: none;
}

.btn.btn-cancel2 {
  background: #dc3545;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgb(0 0 0 / 15%);
  width: auto;
  margin: 0 auto;
  position: relative;
  display: block;
}

.form-group.input-group-prepend-2 {
  margin-right: -1px;
  left: 13%;
  position: relative;
  display: inline-block;
}

.img-doc-3 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 8px solid #fff;
  margin-bottom: 15px;
}

.btn-chat-2 {
  width: 100%;
  margin-bottom: 10%;
}

.white {
  color: white !important ;
}

.bggray {
  background: #001689;
  display: block;
  margin: 0 auto;
  padding: 2%;
  border-radius: 1rem;
}

.label-med {
  background: white;
  padding: 2% 5%;
  border-radius: 20px;
  color: #5584ff;
  font-weight: bold;
}

.bgblue {
  background: #5584ff;
  color: white;
}

.rspan {
  float: left;
  height: 46px;
  padding: 12px;
}

.box-info {
  display: block;
  position: relative;
  margin: auto;
  border-top: solid 2px #5584ff;
  padding: 7px;
  font-size: 14px;
}

.box-info li {
  list-style: none;
}

.img-flag {
  width: 23px;
  height: 23px;
}

.login-logo img {
  cursor: pointer;
  max-width: 171px;
  padding: 23px 15px 22px;
  width: 100%;
}

.login-header .navbar-right {
  margin-right: 0px;
}

.login-header .nav-tabs > li.active > a,
.login-header .nav-tabs > li.active > a:focus,
.login-header .nav-tabs > li.active > a:hover {
  background-color: transparent;
  border: none;
  color: #fff;
}

.login-header .nav-tabs > li > a {
  border: medium none;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  padding: 15px 30px;
  color: #fff;
}

.center-form {
  margin: 0 auto;
  display: block;
  padding-top: 9%;
}

.login-header .nav-tabs {
  border-bottom: none;
}

.login-header .nav-tabs > li {
  margin-bottom: 0px;
}

.login-header .nav > li > a:focus,
.login-header .nav > li > a:hover {
  background: none;
  text-decoration: none;
}

.login-header .nav-tabs > li.active {
  border-bottom: 6px solid #5584ff;
}

.login-inner h1 {
  color: #8492af;
  font-size: 48px;
  font-weight: 300;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.login-inner h1 span {
  color: #5584ff;
}

.login-form {
  text-align: center;
}

.login-form input {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-color: -moz-use-text-color -moz-use-text-color #d4d9e3;
  border-image: none;
  border-style: none none solid;
  border-width: medium medium 1px;
  font-size: 13px;
  font-weight: 300;
  width: 100%;
  color: #8492af;
  padding: 15px 50px;
  font-size: 17px;
  max-width: 550px;
}

.login-form label {
  margin-bottom: 30px;
  width: 100%;
}

.pass input {
  background: rgba(0, 0, 0, 0);
}

.mail input {
  background: rgba(0, 0, 0, 0);
}

.login-signup .tab-content {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  display: inline-block;
  margin-top: -8px;
  width: 100%;
}

.form-btn {
  background: #5584ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 400;
  max-width: 250px;
  padding: 10px 0;
  position: relative;
  width: 100%;
  margin: 40px 0;
  box-shadow: 0 2px 8px #d2d2d2;
  -moz-box-shadow: 0 2px 8px #d2d2d2;
  -webkit-box-shadow: 0 2px 8px #d2d2d2;
}

.outter {
  background: #0192d5;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f32d27', endColorstr='#ff6b45',GradientType=1 );
  width: 100%;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.54);
  -moz-box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.54);
  box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.54);
  color: white;
  padding: 3%;
}

.nofound {
  width: 80%;
  display: block;
  position: relative;
  padding: 6%;
}

.sales .btn-group-g {
  padding: 2%;
  display: block;
  position: relative;
  width: auto;
}

.btn.btn-g {
  background: #2d71a1;
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  width: 108%;
  /* margin: 0 auto; */
  position: relative;
  display: block;
}

.labl-g {
  padding: 5px;
  color: #8492af;
}

.outter2 {
  /* background: #dee2e6; */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f32d27', endColorstr='#ff6b45',GradientType=1 );
  width: 100%;
  /* border: solid 2px #2196f3; */
  margin-top: 5%;
}

.line {
  border-bottom: 2px solid #f5f5f5;
}

.bg-header-modal {
  background: rgb(189 207 225 / 25%);
}

.form-btn::before {
  content: '';
  font-family: FontAwesome;
  position: absolute;
  right: 17px;
  top: 9px;
}

.form-details {
  padding: 35px 0;
}

.tab-content .tab-pane {
  padding: 70px 0;
}

/*Card Credit Card*/
/* .card {
  display: inline-block;
  float: left;
  width: 370px;
  margin-left: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 0;
  border-right: 1px solid lighten($darkblue, 70%);
} */

.card-amount {
  display: block;
  width: 57%;
  /* margin-left: 0.75rem; */
  /* padding-right: 0.75rem; */
  /* padding-left: 0; */
  border: 1px solid #0090d2;
  padding: 3%;
  border-radius: 11px;
}

.card2 {
  display: inline-block;
  float: left;
  width: 100%;
  margin-left: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 0;
  border-right: 1px solid lighten($darkblue, 70%);
}

.edit-i {
  font-size: 27px;
  color: #17839f;
}

.btn-t {
  background: transparent;
  border: none;
}

.btn-t:active > .btn-t:focus > .btn-t:hover {
  background: transparent;
  border: none;
}

.close-i {
  font-size: 27px;
  color: red;
}

.rate {
  float: left;
  height: 46px;
  padding: 0 10px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

/*Home Page*/

.home {
  background: #f6f7fa;
}

#s-sidebar__navgation {
  background: #ffff;
  list-style: none;
}

#s-sidebar__navgation {
  padding: 0;
  top: 82px;
}

.display-table {
  display: table;
  padding: 0;
  height: 100%;
  width: 100%;
}

.display-table-row {
  display: table-row;
  height: 100%;
}

.display-table-cell {
  display: table-cell;
  float: none;
  height: 100%;
  top: 50px;
}

.v-align {
  vertical-align: top;
}
.logo img {
  max-width: 180px;
  padding: 16px 0 17px;
  width: 100%;
}

.header-top {
  margin: 0;
  padding-top: 2px;
  /* width: 100%; */
}

/* .header-top img {
  border-radius: 50%;
  max-width: 48px !important;
  width: 100%;
} */

.b-w {
  overflow-wrap: break-word;
}

.add-project {
  background: #5584ff none repeat scroll 0 0;
  border-radius: 7px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 27px 10px 45px;
  position: relative;
}

.material-switch > input[type='checkbox'] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type='checkbox']:checked + label::before {
  background: #28a745;
  opacity: 0.5;
}
.material-switch > input[type='checkbox']:checked + label::after {
  background: white;
  left: 20px;
}

.header-rightside .nav > li > a:focus,
.header-rightside .nav > li > a:hover {
  background: none;
  text-decoration: none;
}

.add-project::before {
  background: rgba(0, 0, 0, 0);
  content: '';
  height: 12px;
  left: 17px;
  position: absolute;
  top: 12px;
  width: 12px;
}

.btn-add {
  background: #2d71a1;
  background: linear-gradient(-45deg, #1391a5, #274685);
  color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
  /* width: 16%; */
  /* margin: 0 auto; */
  position: relative;
  display: block;
  /* float: right; */
  padding: 0.375rem 0.75rem;
  margin-bottom: 11%;
}

.bluei {
  font-size: 20px;
  color: #5584ff;
  padding: 2px;
}

.bluei2 {
  font-size: 20px;
  color: red;
  padding: 2px;
}

.bluei3 {
  font-size: 20px;
  color: green;
  padding: 2px;
}

.add-project:hover {
  color: #ffffff;
}

.header-top i {
  color: #0e1a35;
}

.icon-info {
  position: relative;
}

.s-sidebar__nav i {
  font-size: 20px;
}

.s-sidebar__nav li {
  list-style: none;
}

form {
  width: 100%;
  text-align: center;
}

.label-txt2 {
  position: absolute;
  top: -1.6em;
  padding: 10px;
  font-size: 0.8em;
  letter-spacing: 1px;
  color: rgb(120, 120, 120);
  transition: ease 0.3s;
}
.input2 {
  width: 100%;
  padding: 10px;
  background: transparent;
  border: none;
  outline: none;
}

.line-box2 {
  position: relative;
  width: 100%;
  height: 2px;
  background: #bcbcbc;
}

.line2 {
  position: absolute;
  width: 0%;
  height: 2px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  background: #0192d5;
  transition: ease 0.6s;
}

.input2:focus + .line-box2 .line2 {
  width: 100%;
}

.label-active2 {
  top: -3em;
}

.label.label-primary {
  border-radius: 50%;
  font-size: 14px;
  left: 7px;
  position: absolute;
  top: -14px;
  color: white;
  background-color: #5584ff;
  width: 26px;
  height: 26px;
}

.icon-info .label {
  border: 2px solid #ffffff;
  font-weight: 500;
  /* padding: 3px 5px; */
  text-align: center;
}

.header-top li {
  display: inline-block;
  text-align: center;
  /* padding: 1%; */
}

.header-top .dropdown-toggle {
  color: white;
}

.header-top .dropdown-menu {
  border: medium none;
  left: -114px;
  padding: 17px;
}
.view {
  background: #6c757d none repeat scroll 0 0;
  border-radius: 36px;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  padding: 5px;
  width: 100px;
}

.view:hover {
  background: #0192d5 none repeat scroll 0 0;
  border-radius: 36px;
  color: #ffffff;
}

.navbar-content > span {
  font-size: 13px;
  font-weight: 700;
}

.btn span.glyphicon {
  opacity: 0;
}

.btn.active span.glyphicon {
  opacity: 1;
}

.img-responsive {
  width: 100%;
}

.text-radio {
  color: white !important;
  padding-left: 5px;
}

#navigation {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.search input {
  border: none;
  font-size: 15px;
  padding: 15px 9px;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  color: #8492af;
}

.navwhite {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.04);
  display: inline-block !important;
  line-height: 23px;
  padding: 15px;
  transition: all 0.5s ease 0s;
  width: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 1;
}

.logo {
  text-align: center;
}

.font-p {
  color: #6c757d;
}

.center-text {
  text-align: center !important;
}

.s-sidebar__nav a {
  /* color: #8da1b5; */
  display: block;
  font-size: 17px;
  font-weight: 500;
  margin: 0 auto;
  text-decoration: none;
}

.s-sidebar__nav i {
  margin-right: 20px;
  color: #c7cadd;
}

.center-logo {
  width: 120px;
  margin: 0 auto;
  position: relative;
  display: block;
}

.s-sidebar__nav a:hover {
  /* border-right: 5px solid #5584ff; */
  display: block;
  /* color: #5584ff; */
  font-weight: bold;
  /* font-size: 30px; */
}

.s-sidebar__nav .active a {
  border-right: 5px solid #5584ff;
  display: block;
  color: #5584ff;
  font-weight: bold;
  font-size: 15px;
}

.s-sidebar__nav .active i {
  color: #5584ff;
}

.s-sidebar__nav i:hover {
  color: #5584ff;
}

.navbar-default {
  background-color: #ffffff;
  border-color: #ffffff;
}

.navbar-toggle {
  border: none;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: rgba(0, 0, 0, 0);
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #0e1a35;
}

.circle-logo {
  margin: 0 auto;
  max-width: 30px !important;
  text-align: center;
}
.hidden-xs {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.card4 {
  display: inline-block;
  float: left;
  width: 92%;
  margin-left: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 0;
  border-right: 1px solid lighten($darkblue, 70%);
}

.edit-right {
  float: right;
  display: block;
  position: relative;
  font-size: 20px;
}

.card-header .title {
  font-size: 17px;
  color: #000;
}
.card-header .accicon {
  float: right;
  font-size: 20px;
  width: 1.2em;
}
.card-header {
  cursor: pointer;
  border-bottom: none;
}
.card {
  border: 1px solid #ddd;
}
.card-body {
  border-top: 1px solid #ddd;
}
.card-header:not(.collapsed) .rotate-icon {
  transform: rotate(180deg);
}
.user-dashboard {
  padding: 0 20px;
}

.user-dashboard h1 {
  color: #0e1a35;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 21px 0;
}

.marb3 {
  margin-bottom: 3%;
}

.border-chart {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 1px 1px 0 rgba(42, 42, 42, 0.08),
    0 1px 3px 1px rgba(42, 42, 42, 0.16) !important;
}

.btn-p-blue {
  color: #fff;
  background-color: #2d71a1;
  border-color: #0192d5;
}

.form-control-user {
  height: 33px;
  /* border-radius: 13px; */
  border-radius: 2rem;
  border: 1px solid #dae0e5;
  width: 55%;
  display: block;
  margin: 0 auto;
}

.sales {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #ddd;
  display: inline-block;
  border-radius: 1.8rem !important;
  padding: 13px;
  width: 100%;
}

/* notification call  */

@keyframes calling {
  from {
    background: rgba(255, 255, 255, 0.1);
  }
  to {
    background: rgba(255, 255, 255, 0.9);
  }
}
@keyframes calling2 {
  to {
    border-color: red;
  }
}
@keyframes declined {
  to {
    -webkit-filter: grayscale(0.1) blur(1px)
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
    -moz-filter: grayscale(0.1) blur(1px)
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.7));
    opacity: 0.5;
  }
}
@keyframes go {
  0% {
    opacity: 0.5;
  }
  95% {
    transform: scale(0.2);
  }
  100% {
    opacity: 0;
  }
}
.call {
  width: 70%;
  height: auto;
  position: relative;
  top: 50%;
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  margin: auto;
  /* box-shadow: 0px 0px 10px 10px rgb(0 0 0 / 10%); */
  border-radius: 1.5rem;
  background: #fff;
  padding-bottom: 20px;
}

.call.is-declined {
  animation: declined 0.4s forwards, go 0.3s 0.8s forwards;
}
.call.is-declined .circle {
  background: rgba(255, 255, 255, 0.1);
}
.call.is-declined .user-photo__wrap,
.call.is-declined .circle {
  animation-play-state: paused;
}
.call.is-accepted header > h1 {
  opacity: 0;
}
.call.is-accepted .circle {
  opacity: 0;
  animation-play-state: paused;
}
.call.is-accepted .user-photo__wrap {
  border: none;
  animation-play-state: paused;
}
.call.is-accepted .user-photo__wrap > img {
  transform: scale(1.2);
}

header > h1 {
  font-size: 1.5em;
  color: white;
  font-weight: 100;
  background: #0190d9;
  position: relative;
  padding: 15px;
  margin-bottom: 2px;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  border: 1px solid #0190d9;
  text-align: center;
}

.user {
  width: 100%;
}

.user-photo {
  width: 100px;
  height: 100px;
  padding: 10px;
  margin: 0 auto;
  border-radius: 100%;
  background: #8700a7;
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.8),
    inset 1px 1px 1px rgba(0, 0, 0, 0.3), inset -1px -1px 1px rgba(0, 0, 0, 0.3),
    inset 2px 2px 1px rgba(0, 0, 0, 0.3), inset 1px 3px 1px rgba(0, 0, 0, 0.3);
}
.user-photo__wrap {
  background: #d10eff;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 5px solid #d10eff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.6);
  animation: calling2 1.3s linear infinite;
}
.user-photo__wrap > img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: transform 0.2s;
}
.user-name {
  margin: 25px 0;
  text-align: center;
  /* color: white; */
  font-size: 18px;
}
.status {
  width: 100px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  color: #5584ff;
  text-align: center;
  background: rgb(0 123 255 / 25%);
}

.status .circle {
  float: left;
  margin-right: 12px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
  animation: calling 1s backwards infinite;
}
.status .circle:nth-child(1) {
  animation-delay: 0.2s;
}
.status .circle:nth-child(2) {
  animation-delay: 0.4s;
}
.status .circle:nth-child(3) {
  animation-delay: 0.6s;
}
.status .circle:nth-child(4) {
  animation-delay: 0.8s;
}
.status .circle:nth-child(5) {
  animation-delay: 1s;
}
.status .circle.last {
  margin-right: 0;
}
.buttons {
  width: 90%;
  margin: 20px auto;
}
.buttons .col {
  width: 50%;
  float: left;
}
.buttons .button {
  margin: 0 auto;
  width: 80%;
  display: block;
  padding: 10px 20px;
  text-align: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: white;
  text-decoration: none;
}
.buttons .button.-decline {
  border-color: #f24352;
  background: rgba(203, 0, 18, 0.14);
}
.buttons .button.-decline:hover {
  background: #f24352;
  color: white;
}
.buttons .button.-accept {
  border-color: #c6eea3;
  background: rgb(39 80 0 / 12%);
}
.buttons .button.-accept:hover {
  background: #6bce15;
  color: white;
}
/* notification call  */

.sales .btn-group {
  float: right;
}

.pad-right {
  display: block;
  position: relative;
  float: right;
  padding: 4px;
}

.padd-left {
  float: left;
  display: block;
  position: relative;
  padding: 4px;
}

.sales h2 {
  color: #8492af;
  float: left;
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  padding: 9px 0 0;
}
.btn.btn-secondary.btn-lg.dropdown-toggle > span {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.sales .dropdown-menu {
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 8px;
  width: 100%;
  color: #0e1a35;
}
.sales .btn-group.open .dropdown-toggle,
.btn.active,
.btn:active {
  box-shadow: none;
}
.sales .dropdown-menu > a {
  color: #0e1a35;
  display: inline-block;
  font-weight: 800;
  padding: 9px 0;
  text-align: center;
  width: 100%;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

#my-cool-chart svg {
  width: 100%;
}
.sales .dropdown-menu > a:hover {
  color: #5584ff;
}
.shield-buttons {
  display: none;
}
.close,
.close:focus,
.close:hover {
  color: #fff;
  opacity: 1;
  text-shadow: none;
}
.modal-body input {
  border: 1px solid #d4d9e3;
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
  padding: 14px 10px;
  width: 100%;
  color: #8492af;
}
.modal-body textarea {
  border: 1px solid #d4d9e3;
  font-size: 14px;
  font-weight: 300;
  height: 200px;
  margin-top: 5px;
  padding: 9px 10px;
  width: 100%;
  color: #8492af;
}
.modal-header.login-header h4 {
  color: #ffffff;
}
.modal-footer .add-project {
  background: #5584ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 100px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 30px;
  position: relative;
}
.modal-footer .add-project::before {
  display: none;
}
.modal-footer {
  border: 0 none;
  padding: 10px 15px 26px;
  text-align: right;
}
.cancel {
  background: #0e1a35;
  border: medium none;
  border-radius: 100px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 30px;
  position: relative;
}

.modal-header .close {
  /* margin-top: 2px; */
  color: black;
}

.s-sidebar__nav a.pl {
  padding-left: 40px;
}

.search input:focus {
  border-bottom: 1px solid #bdc4d4;
  line-height: 22px;
  transition: 0.1s all;
}
.modal-header.login-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media only screen and (max-device-width: 767px) {
  .login-logo img {
    margin: 0 auto;
  }
  .login-details .nav-tabs > li {
    text-align: center;
    width: 50%;
  }

  .sales h3 {
    color: #8492af;
    /* float: left; */
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    padding: 9px 0 0;
    word-break: break-word;
  }

  .login-signup .login-inner h1 {
    font-size: 26px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .login-inner .login-form input {
    font-size: 15px;
    max-width: 100%;
    padding: 15px 45px;
  }
  .login-inner .form-details {
    padding: 25px;
  }
  .login-inner .login-form label {
    margin-bottom: 20px;
    width: 100%;
  }
  .login-inner .form-btn {
    margin: 0;
    max-width: 180px;
  }
  .tab-content .tab-pane {
    padding: 20px 0;
  }
  #navigation .s-sidebar__nav a {
    font-size: 14px;
    padding: 20px;
    text-align: center;
  }
  #navigation .s-sidebar__nav i {
    margin-right: 0px;
  }
  #navigation .s-sidebar__nav a:hover,
  #navigation .s-sidebar__nav .active a {
    display: block;
    border-right: 3px solid #0192d5;
    padding-left: 40px;
    color: #0192d5;
  }
  header .header-top img {
    max-width: 38px !important;
  }
  .v-align header {
    padding: 12px 15px;
  }
  header .header-top li {
    padding-left: 13px;
    padding-right: 6px;
  }
  .navbar-default .navbar-toggle {
    border-color: rgba(0, 0, 0, 0);
  }
  .navbar-header .navbar-toggle {
    float: left;
    margin: 0;
    padding: 0;
    top: 12px;
  }
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    outline: medium none;
  }
  .user-dashboard .sales h2 {
    color: #8492af;
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 13px 0 0;
  }

  .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
    font-size: 11px;
  }
  .user-dashboard .sales button {
    font-size: 11px;
    padding-right: 23px;
  }
  .user-dashboard .sales h2 {
    font-size: 14px;
    float: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .form-control-user {
    font-size: 14px;
    width: 100%;
  }

  .gutter {
    padding: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sales .user-detail .img-bg-doc {
    width: 252px;
    z-index: 2;
    position: relative;
    bottom: 0px;
    height: auto;
    left: 20px;
  }
}

@media only screen and (max-device-width: 992px) {
  header .header-top li {
    padding-left: 20px !important;
    padding-right: 0;
  }
  header .logo img {
    max-width: 125px !important;
  }
}

@media only screen and (min-device-width: 767px) and (max-device-width: 998px) {
  .user-dashboard .header-top {
    padding-top: 5px;
  }
  .user-dashboard .header-rightside {
    display: inline-block;
    float: left;
    width: 100%;
  }
  .user-dashboard .header-rightside .header-top img {
    max-width: 41px !important;
  }
  .user-dashboard .sales button {
    font-size: 10px;
  }
  .user-dashboard .btn.btn-secondary.btn-lg.dropdown-toggle > span {
    font-size: 12px;
  }
  .user-dashboard .sales h2 {
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 998px) and (max-device-width: 1350px) {
  #s-sidebar__navgation .logo img {
    max-width: 130px;
    padding: 16px 0 17px;
    width: 100%;
  }
}

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  min-height: 109vh;
  /* background-color: #f6f7fa; */
}

.s-layout__content {
  display: block;
  flex: 1 1;
  position: relative;
}

/* Sidebar */
.s-sidebar__trigger {
  z-index: 1;
  position: relative;
  top: -8px;
  /* left: 60px; */
  width: 100%;
  /* height: 5em; */
  /* background: #192b3c; */
}

.s-sidebar__trigger > i {
  display: inline-block;
  margin: 1.5em 0 0 1.5em;
  color: #0a81f0;
}

.s-sidebar__nav {
  z-index: 3;
  position: fixed;
  top: 0;
  left: -15em;
  overflow: hidden;
  transition: all 0.3s ease-in;
  width: 13em;
  height: 100%;
  background: #fff;
  color: rgba(255, 255, 255, 0.7);
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
  left: 0;
}

.s-sidebar__nav ul {
  position: absolute;
  top: 7em;
  left: 0;
  margin: 0;
  padding: 0;
  width: 13em;
}

.s-sidebar__nav ul li {
  width: 100%;
}

.s-sidebar__nav-link {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4em;
}

.s-sidebar__nav-link em {
  position: absolute;
  top: 50%;
  left: 4em;
  transform: translateY(-50%);
}

.s-sidebar__nav-link:hover {
  background: #4d6276;
}

.s-sidebar__nav-link > i {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 4em;
  height: 4em;
}

.s-sidebar__nav-link > i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Mobile First */
@media (min-width: 42em) {
  .s-layout__content {
    margin-left: 4em;
  }

  /* Sidebar */
  .s-sidebar__trigger {
    width: 4em;
  }

  .form-control-search {
    width: 58%;
  }

  .i-search {
    left: -70px;
  }

  /* .site-navbar .site-navigation .site-menu > li > a {
    padding: 0 16px;
    color: #fff;
    display: inline-block;
    text-decoration: none !important;
    font-size: 11px;
  } */

  .font-blue {
    color: #001689;
    font-weight: bold;
    font-size: 19px;
  }

  .pad-left {
    padding-left: 5%;
    /* font-size: 12px; */
  }

  .s-sidebar__nav {
    width: 4.5em;
    left: 0;
  }

  .links-container span {
    display: none;
  }

  .menu-div {
    width: 65px;
  }

  /* .s-sidebar__nav a {
    padding-left: 13px;
  } */

  .s-sidebar__nav a.pl {
    padding-left: 0px;
  }

  .profile-card-1 .card-content {
    padding: 0px;
  }

  /* 
  .s-sidebar__nav:hover,
  .s-sidebar__nav:focus,
  .s-sidebar__trigger:hover + .s-sidebar__nav,
  .s-sidebar__trigger:focus + .s-sidebar__nav {
    width: 13em;
  } */
}

@media (min-width: 68em) {
  .s-layout__content {
    margin-left: 15em;
  }

  /* 
  .links-container span {
    display: initial;
  } */

  /* Sidebar */
  .s-sidebar__trigger {
    display: none;
  }

  /* .s-sidebar__nav {
    width: 13em;
  } */

  .s-sidebar__nav ul {
    top: 1.3em;
  }

  .menu-div {
    width: 126px;
    height: 100vh;
    /* padding-left: 2%; */
  }

  .links-container span {
    display: block;
    position: relative;
  }

  .s-sidebar__nav {
    width: 132px;
  }
}

/* REESTRUCTURACION */

.menu-items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  flex: 1;
}
.logo-dashboard {
  margin-top: 2rem;
}

.links-container {
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  /* padding-left: 5px; */
  max-height: calc(100vh - 7rem);
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  margin-top: 95px;
}

/* ocultar scroll */
.links-container::-webkit-scrollbar {
  display: none;
}

.nav-link-menu {
  padding: 10px;
  text-decoration: none;
  display: flex;
  border-radius: 0.8rem;
  flex-direction: row;
  align-items: center;
  font-weight: lighter;
  text-decoration: none !important;
}

.nav-link-menu:hover {
  color: #001689 !important;
  border-radius: 0.8rem;
  background-color: #ffdd00;
  font-weight: lighter !important;
  text-decoration: none;
  transition-duration: 2s;
  transition-timing-function: linear;
}
.nav-active-link-menu {
  border-right: 5px solid #0093d3;
  text-decoration: none !important;
}

/* vista mobile */
@media (max-width: 600px) {
  .line-w {
    padding: 5%;
  }

  .cont-r {
    margin-top: 43%;
  }

  .jumbotron {
    margin-bottom: 8rem;
  }

  .s-layout {
    display: flex;
    width: 100%;
    min-height: auto;
    /* background-color: #f6f7fa; */
  }

  .check-green {
    font-size: 14px;
    border: solid 1px #24f233;
    padding: 1%;
  }

  .check-red {
    font-size: 14px;
    border: solid 1px #cf0e10;
    padding: 1%;
  }

  .check-yellow {
    font-size: 14px;
    border: solid 1px #b7b7b4;
    padding: 1%;
  }

  .mesgs {
    padding: 0;
  }

  .center-rw {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mt-4,
  .my-4 {
    margin-top: 2rem !important;
  }

  .img-doc-p {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 5px solid;
    margin-bottom: 0px;
    /* margin-top: 19%; */
  }

  div.overlay-doc {
    background: rgba(175, 175, 175, 0.6);
    /* background:  #d0cfcb; */
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }

  .scroll-doctor {
    min-height: 300px;
    max-height: 300px;
    overflow-x: hidden;
  }

  .icon-card {
    width: 90px;
    margin: 0 auto;
    display: block;
  }
  .bor-r {
    border-radius: 1rem !important;
  }

  .image-overlay {
    position: absolute;
    top: 130px;
  }

  .s-sidebar__nav a.pl {
    padding-left: 0px;
  }

  .form-group {
    margin-bottom: 1rem;
    padding: 0;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    text-align: center;
  }

  .modal-header {
    border-bottom: transparent;
    align-items: center;
  }

  .main-div {
    max-width: 80%;
    margin-top: 42%;
    border-radius: 2rem;
  }

  .mb-24 {
    margin-bottom: 24%;
  }

  .sales h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0 9px;
    word-break: break-word;
  }

  video {
    height: auto;
    width: 300px;
    object-fit: cover;
    padding: 8px;
  }
  .menu-div {
    width: 0px;
    position: relative;
    z-index: 4;
  }

  .footer-img {
    width: 200px;
    float: right;
    display: block;
    margin-top: 20%;
  }

  .section-heading {
    font-size: 1.3rem;
    font-weight: 700;
    background: linear-gradient(-45deg, #3db3c5, #274685);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .principal-layout {
    width: 100%;
  }

  .i-search {
    display: flex;
    position: relative;
    float: right;
    font-size: 25px;
    top: -36px;
    color: #0192d5;
    left: -13px;
  }

  .s-sidebar__trigger > i {
    display: inline-block;
    margin: 1.9em 0 0 0.8em;
    color: #0a81f0;
  }

  .form-select {
    width: 170px;
  }

  .nav-pills .nav-link {
    border-radius: 0;
    color: #001689 !important;
    background-color: #fff;
    font-size: 11.5px;
    text-transform: uppercase;
    padding: 11px;
    font-weight: bold;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 11.5px;
    padding: 11px;
    background-color: #001689;
    font-weight: bold;
  }

  .bg-primary-movil {
    background-color: #001689;
  }

  .hero-section-beat-login {
    background-image: none;
    background-color: #001689;
  }

  .hero-section-beat-registro {
    background-image: none;
    background-color: #001689;
    height: auto;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .fixed-top-navbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: auto;
  }

  .links-container {
    display: flex;
    justify-content: flex-start;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    margin-top: 0;
  }

  .text-responsive {
    font-size: 18px;
  }

  .nav-short {
    padding: 0;
    width: auto;
    border-bottom-left-radius: 0rem;
  }

  .grid-layout-dashboard {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .navbar-toggler i {
    color: #ffff !important;
  }

  .footer-simple {
    position: relative;
    bottom: 0;
    text-align: center;
  }

  .box-text {
    padding-left: 0;
    padding-top: 0;
    text-align: center;
  }

  .display-4 {
    font-size: 1.75rem;
  }

  .badge-type-profile {
    margin-left: 0px;
    margin-top: 8px;
  }

  .base-structure {
    justify-content: space-around;
    padding: 0;
  }

  .base-structure h5 {
    font-size: 13px;
  }

  .base-structure p {
    font-size: 11px;
  }
}

/* different techniques for tablets screening orientación vertical*/
@media only screen and (min-device-width: 576px) and (max-device-width: 1366px) and (orientation: portrait) {
  .text-responsive {
    font-size: 16px;
  }

  .importante {
    bottom: 0;
    top: 130px;
    position: relative;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-layout-hero {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 20px;
  }

  .grid-layout-dashboard {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .hero-section-beat-homepage {
    background-image: none;
    background-color: #001689;
    min-height: 600px;
    top: -110px;
  }

  .hero-section-beat-login {
    background-image: none;
    background-color: #001689;
  }

  .hero-section-beat-registro {
    background-image: none;
    background-color: #001689;
    height: auto;
    min-height: 600px;
    top: -90px;
  }

  .text-overlapping {
    position: absolute;
    z-index: 1999;
    top: 30px;
    left: 0;
    right: 0;
  }

  .center-on-tablets {
    justify-content: center !important;
  }

  .nav-pills .nav-link {
    border-radius: 0;
    color: #001689 !important;
    background-color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    padding: 13px;
    font-weight: bold;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff !important;
    text-transform: uppercase;
    font-size: 13px;
    padding: 13px;
    background-color: #001689;
    font-weight: bold;
  }

  .navbar-toggler {
    position: fixed;
    right: 0;
  }

  .navbar-toggler i {
    color: #001689;
  }

  .section-features {
    padding-top: 380px;
    padding-bottom: 100px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem;
  }

  .display-4 {
    font-size: 28px;
  }

  .imga {
    height: 60vh;
    width: 95%;
    margin-left: auto;
  }

  .footer-simple {
    position: relative;
    bottom: 0;
    text-align: center;
  }

  .box-text {
    padding-left: 40px;
    padding-top: 10px;
    width: 480px;
  }

  .nav-short {
    padding: 5px;
    border-radius: 2rem;
    width: 98%;
    position: absolute;
    margin: 0;
    flex-direction: initial;
  }

  .badge-type-profile {
    margin-left: 0px;
    margin-top: 2px;
    font-size: 7.5px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    padding-right: 4px;
  }
}

/* different techniques for tablets screening orientación Horizontal */
@media only screen and (min-device-width: 576px) and (max-device-width: 1366px) and (orientation: landscape) {
  .grid-layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-layout-dashboard {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .grid-layout-hero {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 20px;
  }

  .hero-section-beat-homepage {
    background-image: none;
    background-color: #001689;
    min-height: 630px;
    top: -110px;
  }

  .hero-section-beat-login {
    background-image: none;
    background-color: #001689;
  }

  .hero-section-beat-registro {
    background-image: none;
    background-color: #001689;
    min-height: 630px;
    height: auto;
  }

  .text-overlapping {
    position: absolute;
    z-index: 1999;
    top: 80px;
    left: 0;
    right: 0;
  }

  .section-features {
    padding-top: 360px;
    padding-bottom: 140px;
  }

  .center-on-tablets {
    justify-content: center !important;
  }

  .display-4 {
    font-size: 30px;
  }

  .imga {
    height: 100vh;
    width: 95%;
    margin-left: auto;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    z-index: 1;
    font-size: 8.5px !important;
  }

  .nav-short {
    padding: 24px;
  }

  .footer-simple {
    position: relative;
    bottom: 0;
    text-align: center;
  }

  .box-text {
    padding-left: 50px;
    padding-top: 110px;
    width: 500px;
  }

  .importante {
    bottom: 0;
    top: 100px;
    position: relative;
  }
}
