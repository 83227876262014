.credit-card-container {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 0.8rem;
  margin-bottom: 5px;
}

.card-item-name {
  flex: 1;
  /* font-weight: 500;
  font-size: 18px; */
}
.card-item-prefix {
  flex: 1;
  font-weight: bold;
  font-size: 13px; 
  color: #0c87e7;
}
.credit-card-image {
  width: 50px;
  margin-right: 8px;
  padding: 3px;
}
.credit-card-selected {
  background-color: #fff;
}
.checkbox-card-item {
  margin: 0;
  padding-right: 10px;
}

/* vista version movil de tarjetas */
@media (max-width: 600px) {
  .credit-card-container {
    height: 60px;
    padding: 3px;
    margin-bottom: 3px;
  }
  .checkbox-card-item {
    padding-right: 5px;
  }
  .credit-card-image {
    width: 30px;
    margin-right: 5px;
    padding: 2px;
  }
  .card-item-prefix {
    font-weight: bold;
    font-size: 11px; 
    color: #0c87e7;
  }
  .card-item-name {
    font-weight: 500;
    font-size: 12px;
  }
}
