.nav-container {
  display: flex;
  width: 100% !important;
  height: 80px;
  background-color: #fff !important;
}

.main-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 100%;
  padding-top: 80px;
  overflow: hidden;
  position: relative;
}

.principal-layout {
  background-color: #fafafa !important;
  height: 100%;
}

.nav-items-container {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* shopping dropdown */
.toggle-shopping {
  color: #001689 !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* logout styles */
.plan-medico {
  background-color: #FFEB01;
}
