.container-scheduling {
  z-index: 0;
  margin-top: 40px;
}
.agendar-button {
  outline: none !important;
}

.buttons-container-scheduling {
  display: flex;
  flex-direction: row;
}
.close-button-scheduling {
  background-color: #ed5a59 !important;
  margin: 3px !important;
}
.add-button-scheduling {
  margin: 3px !important;
}
.textarea-properties {
  resize: none;
  height: 100px !important;
}
.hour-modal-add-date {
  width: '50%' !important;
}

.avatar-modal {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  border: 2px solid white;
}
.modal-header-content-enabled {
  background-color: #18c523 !important;
  padding: 15px;
  flex: 1;
  display: flex;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-bottom: 20px;
}
.modal-header-content-disabled {
  background-color: #0c87e7 !important;
  padding: 15px;
  flex: 1;
  display: flex;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-bottom: 20px;
}
.modal-header-content-reject {
  background-color: #cf0e10 !important;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  margin-bottom: 20px;
  padding: 0.4rem;
  flex: 1;
  display: flex;
}
.date-modal-container {
  display: flex;
  margin-top: 0.6rem;
  flex-direction: column;
}

.doctor-content-modal {
  display: flex;
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-modal {
  width: 370px;
}
.dialog-modal-profile-image {
  paddingtop: 20px;
}
.accept-button {
  background-color: #0093d3;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 5px 10px 5px 10px;
  color: #fff;
  transition: all ease-in-out 0.3s;
}

.reject-button {
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 5px 10px 5px 10px;
  background-color: #c60007;
  color: #fff;
  transition: all ease-in-out 0.3s;
}
.reject-button:hover {
  background-color: #ad1318;
}
.accept-button:hover {
  background-color: #40b3e5;
}

.agendar-cita-boton {
  font-size: 14px;
  background-color: #001689 !important ;
  border-radius: 15px;
  font-size: 12px;
}

/* estilos modal para doctor  compoenente DoctorList */

.backdrop-style {
  background-color: transparent;
}

.modal-doctor-scheduling {
  border: none !important;
}

.button-aceptar-scheduling {
  background-color: #0093d3 !important;
  border-radius: 13px !important;
}
.title-modal-doctor-scheduling {
  color: #001689;
}

.button-cancelar-doctor-scheduling {
  color: #f7f7f7 !important;
  border-radius: 13px !important;
}
.dialog-modal-doctor {
  border-radius: none !important;
}
.search-doctor-scheduling {
  margin: 0 !important;
}
.reagendar-dialog,
.agendar-dialog {
  min-width: 550px !important;
}

/* hoario desactivado */
.disable-button-hour {
  text-decoration: line-through;
  color: black !important;
}

.disable-button-hour:hover {
  text-decoration: line-through;
  color: black !important;
}

.textarea::placeholder {
  color: red;
  font-weight: 500;
}
