.button-search-style {
  border-radius: 0px !important;
  padding: 0 !important;
  background-color: transparent !important;
  color: rgb(160, 160, 160) !important;
  outline: none;
  cursor: pointer;
  padding: 0px;
  width: 100%;
}

.button-search-style:focus {
  outline: 0 !important;
}
.button-search-style:active {
  outline: none;
}

.input-group-search {
  border-radius: 0px;
  border-right: none;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.input-group-search-button {
  cursor: pointer;
  border-radius: 0px;
  border-left: none;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.form-select {
  border: 0 !important;
}
.react-select-container {
  border-radius: 50%;
}
