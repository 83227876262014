.tabsHeader {
  font-size: 15px;
}
.tabContent {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.flex-justify-center-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-company-list-container {
  width: 100%;
  height: 300px;
}
