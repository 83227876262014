/* contenedor principal de notificaciones */
.container-notifications {
  position: relative;
}
.bell {
  margin-bottom: 10px;
  cursor: pointer;
}

.badge-container {
  position: absolute;
  left: -6px;
  top: -14px !important;
  font-size: 0.5rem;
  color: #fff !important;
  background-color: #0c87e7;
}

.hide-dropdown {
  display: none;
}

.sub-menu {
  position: absolute;
  top: 30px;
  right: 0px;
  padding: 15px;
  width: 300px;
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
.sub-menu:hover {
  box-shadow: 10px 10px 5px -9px rgba(45, 113, 161, 0.2);
}
.sub-menu-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.sub-menu-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
}
.sub-menu-title-notification {
  margin: 0 auto;
  flex: 1;
  font-size: 14px;
}
.quantity-notifications {
  flex: 0;
}

.notification-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: row;
}
.notification {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-mail {
  flex: 1;
}
.notification-message {
  color: #5984a0;
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  line-height: normal;
}

.notification-date {
  font-size: 12px;
  padding-top: 5px;
  text-align: left;
}
.button-ver {
  background-color: transparent;
  border: none;
  color: #e60022;
  font-weight: 500;
  outline: none;
  margin: 0;
  padding: 0;

  box-shadow: none;
}
.button-ver:hover {
  text-decoration: underline;
  outline: none;
}

.individual-notification-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid #eaf6ff;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
}

.individual-notification-container:hover {
  background-color: #f1f1f1;
  transition: all cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 1s 0s ease;
}
